import React, { useEffect, useRef } from 'react'
import Footer from './Footer'
import { useNavigate } from 'react-router'
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';


function Contact() {
    const form = useRef();
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"



        }
    })
    const navigate = useNavigate("")
    const contact = (e) =>{
        e.preventDefault()
            emailjs
              .sendForm(
                "service_cby7da4",
                "template_rf4fgsi",
                form.current,
                "Z3DilWMnxO_DeDuk2"
              )
              .then(
                (result) => {
                    navigate("/ContactDone")

                },
                (error) => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                  });
                }
              );
            e.target.reset();
    }
    return (
        <>
            <div className="contact">
                <div className="frame-parent mt-5">
               
                    <div className="frame-wrapper5">
                        <div className="frame-parent3">
                            <div className="have-any-questions-parent">
                                <div className="have-any-questions">Have any questions ?</div>
                                <div className="contact-with-us">Contact with us Now !</div>
                            </div>
                            <img className="undraw-envelope-re-f5j4-1-icon" alt="" src="./assets/img/undraw_envelope_re_f5j4 1.png" />
                            <div className="frame-child">
                            </div>
                            <div className="frame-wrapper6">
                                <div className="facebooklogo-parent">
                                    <img className="facebooklogo-icon" alt="" src="./assets/img/FacebookLogo.svg" />
                                    <img className="facebooklogo-icon" alt="" src="./assets/img/Vector (1).svg" />
                                    <img className="facebooklogo-icon" alt="" src="./assets/img/XLogo.svg" />
                                    <img className="facebooklogo-icon" alt="" src="./assets/img/LinkedinLogo.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-wrapper">
                        <form className="frame-group" onSubmit={(e)=>contact(e)} ref={form} >
                            <div className="frame-container">
                                <div className="frame-div">
                                    <div className="frame-wrapper1">
                                        <div className="full-name-wrapper">
                                            <div className="full-name">Full name</div>
                                        </div>
                                    </div>
                                    <input className="enter-your-full-name-here-wrapper" name='from_name' placeholder='Enter your full name here'/>
                                        <div className="enter-your-full"></div>
                                    
                                </div>
                            </div>
                            <div className="frame-container">
                                <div className="frame-div">
                                    <div className="frame-wrapper1">
                                        <div className="full-name-wrapper">
                                            <div className="full-name">Email</div>
                                        </div>
                                    </div>
                                    <input className="enter-your-full-name-here-wrapper" type='email' name='email' placeholder='Enter your email name here'/>
                                        <div className="enter-your-full"></div>
                                </div>
                            </div>
                            <div className="frame-wrapper1">
                                <div className="frame-wrapper1">
                                    <div className="full-name-wrapper">
                                        <div className="full-name">Your message</div>
                                    </div>
                                </div>
                                <textarea className="enter-your-message-here-wrapper" name='message' placeholder='Enter your message here'>
                                </textarea>
                            </div>
                            <button type='submit' className="submit-wrapper" id="frameContainer3">
                                <div className="full-name">Submit</div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Contact
