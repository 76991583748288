import React from 'react'
import Footer from './Footer'

function ContactDone() {
    
    return (
        <>
            <div className='re-rest-pass'>
                <div className="send-link" id="sendLinkContainer"  style={{backgroundColor:"#F7F7F7"}}>
                    <div className="send-message-1-1-parent">
                        <img className="send-message-1-1" alt="" src="./assets/img/contact-done.png" />
                        <div className="we-sent-you">Sent successfully, we will respond to your message as soon <br />as possible.</div>
                   
                    
                    </div>

                </div>
            </div>
            <Footer /> 
        </>
    )
}

export default ContactDone
