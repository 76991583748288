import React from 'react'
import Footer from './Footer'
import { useEffect } from "react";

function About() {
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"
        }
    })
    return (
        <>
            <div className="about-us">
                <img src="./assets/img/Groupabout.svg" alt="" className='aboutp-img' />
                <div className="display">
                    <div className="man">
                        <img src="./assets/img/man-about.png" alt="" />
                    </div>
                    <div className="typegraphy0">
                        <span> Welcome to ReRest, your ultimate companion for finding the cleanest and most accessible restrooms
                            wherever
                            you are. Our platform is designed to help you locate the best restrooms quickly and conveniently,
                            using
                            advanced geolocation technology. Whether you're traveling, exploring new places, or just in need of
                            a nearby
                            restroom, ReRest offers user-generated reviews and ratings to guide your choices. With options to
                            filter by
                            cleanliness, accessibility, amenities, and more, we ensure you can find a restroom that meets your
                            specific
                            needs. Our goal is to provide a reliable and efficient service that enhances your comfort and
                            convenience,
                            making your restroom search hassle-free and straightforward.
                        </span>
                    </div>

                    <div className="location">
                        <img src="./assets/img/Location.png" alt="" />
                    </div>


                </div>

            </div>
            <Footer />
        </>
    )
}

export default About
