import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from "axios"
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router';
import Footer from './Footer';
import { IoIosArrowDropup } from 'react-icons/io';

const mapContainerStyle = {
    width: '100%',
    height: '300px',
};

const initialCenter = {
    lat: 40.712776,
    lng: -74.005974
};

function UpdateRoom() {
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"
        }
    })
    const allDays = [
        "SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY",
        "THURSDAY", "FRIDAY", "SATURDAY"
    ];
    const [zoom, setZoom] = useState(15);

    const restroomId = useParams()
    const [isTimelineVisible, setIsTimelineVisible] = useState(false);
    const [dayTimes, setDayTimes] = useState({
        time0: false,
        time01: false,
        time02: false,
        time03: false,
        time04: false,
        time05: false,
        time06: false,
    });
    const [selectedPosition, setSelectedPosition] = useState(null); // Initialize as null
    const [searchLocation, setSearchLocation] = useState(initialCenter);
    const navigate = useNavigate("")


    const [formData, setFormData] = useState({
        name: '',
        address: '',
        longitude: 0,
        latitude: 0,
        gender: '',
        type: '',
        categoryId: 13,
        isFree: '',
        needKeyAccess: '',
        note: '',
        facilitiesIds: [],
        openHours: []
    });
    const [photos, setPhotos] = useState([]);
    const [photoPreviews, setPhotoPreviews] = useState([]);

    const toggleTimeline = () => {
        setIsTimelineVisible(!isTimelineVisible);
    };
    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/SignIn")
        }
    }, []);
    useEffect(() => {
        const fetchRoomData = async () => {
            try {
                const token = localStorage.getItem("authToken");

                const response = await axios.get(`https://api.rerests.com/api/v1/rest-room/${restroomId.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const roomData = response.data.data;

                // Populate formData with the fetched room data
                setFormData({
                    name: roomData.name || '',
                    address: roomData.address || '',
                    longitude: roomData.longitude || 0,
                    latitude: roomData.latitude || 0,
                    gender: roomData.gender || '',
                    type: roomData.type || '',
                    categoryId: roomData.categoryId || 13,
                    isFree: roomData.isFree || '',
                    needKeyAccess: roomData.needKeyAccess || '',
                    note: roomData.note || '',
                    facilitiesIds: roomData.facilities || [],
                    openHours: []
                });

                setPhotos(roomData.photos?.map(photo => photo.url) || []);
                setPhotoPreviews(roomData.photos?.map(photo => `${photo.url}`) || []);

                // function formatTime(time) {
                //     const [hours, minutes] = time?.split(':');
                //     return `${parseInt(hours, 10) % 12 || 12}:${minutes} ${hours < 12 ? 'AM' : 'PM'}`; // Convert to 12-hour format
                // }

                // const newDayTimes = {};
                // roomData.openHours.forEach((hour, index) => {
                //     newDayTimes[`time0${index}`] = hour.isOpen === true; // Ensure boolean comparison
                // });
                // setDayTimes(newDayTimes);

            } catch (error) {
                console.error('Error fetching room data:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Fetch Data',
                    text: error?.response?.data?.message || "Something went wrong while fetching room data.",
                });
            }
        };

        fetchRoomData();
    }, [restroomId.id]);  // Trigger effect when the room ID changes

    const toggleDayTime = (key) => {
        setDayTimes((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleCheckboxChangeg = (e) => {
        const { name, value, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: checked ? value : ""
        }));
    };


    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: checked ? Number(value) : 0
        }));
    };

    const handleFacilityChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            facilitiesIds: checked
                ? [...prevState.facilitiesIds, value]
                : prevState.facilitiesIds.filter(id => id !== value)
        }));
    };

    const handleRadioChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handlePhotoUpload = (e) => {
        const files = Array.from(e.target.files);
        const newPhotosPromises = files?.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    // Extract Base64 part from the full Data URL
                    const base64Data = base64String.split(',')[1];
                    resolve(base64Data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newPhotosPromises).then((newPhotos) => {
            setPhotoPreviews(prev => [...prev, ...newPhotos.map(data => `data:image/png;base64,${data}`)]); // For preview
            setPhotos(prev => [...prev, ...newPhotos]); // For payload
        }).catch(error => {
            console.error('Error reading files:', error);
        });
    };

    const handleMapClick = useCallback((event) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        setSelectedPosition(newPosition); // Update state
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const openHours = Object.keys(dayTimes)?.map((key, index) => {
            if (dayTimes[key]) {
                const startTime = e.target[`startTime${index}`]?.value || '00:00';
                const endTime = e.target[`endTime${index}`]?.value || '23:59';

                return {
                    day: ['SATURDAY', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'][index],
                    startTime: `${startTime}:00`,
                    endTime: `${endTime}:00`,
                    isOpen: "true"
                };
            }else{

                return {
                    day: ['SATURDAY', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'][index],
                    startTime: null,
                    endTime: null,
                    isOpen: "false"
                };
            }
        }).filter(Boolean);

        let payload = {
            ...formData,
            facilitiesIds: Array.from(e.target.querySelectorAll('input[name="facility"]:checked')).map(el => Number(el.value)),
            photos: photos.map(photo => ({ url: photo })), // Only Base64 part for payload
            longitude: selectedPosition ? selectedPosition.lng : '',
            latitude: selectedPosition ? selectedPosition.lat : '',
            openHours,
        };
        console.log(payload);
        

        // Remove empty values from the payload
        payload = Object.fromEntries(
            Object.entries(payload).filter(([_, value]) =>
                value !== '' && value !== null && value !== undefined &&
                !(Array.isArray(value) && value.length === 0)
            )
        );

        try {
            const token = localStorage.getItem("authToken");

            await axios.post(`https://api.rerests.com/api/v1/rest-room/${restroomId.id}/request`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            Swal.fire({
                title: "Success message",
                text: "Your update sent successfully",
                customClass: "styleTitle",
                position: 'top-end', // Top-right corner
                toast: true, // Make it a toast
                showConfirmButton: false,
                timer: 3000, // Auto-dismiss after 3 seconds
                timerProgressBar: true,
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: ' Something Went Wrong',
                text: error ? error.response.data.message : "Something went wrong",
            });
        }
    };

    const handleRemovePhoto = (index) => {
        setPhotoPreviews(prev => prev.filter((_, i) => i !== index));
        setPhotos(prev => prev.filter((_, i) => i !== index));
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const cancelform = () => {

        Swal.fire({
            title: '<div class="warning-parent"><img class="vector-icon" alt="" src="assets/img/warning.svg"><b class="warning">Warning!</b><div class="you-are-one">You are one step away from canceling this operation</div><b class="are-you-sure">Are you sure to cancel?</b><div class="frame-parent"><div class="no-wrapper" id="customCancel"><button class="no">No</button></div><div class="yes-wrapper" id="customConfirm"><button class="yes">Yes</button></div></div></div>',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            customClass: "warnny",
            didOpen: () => {
                document.getElementById('customConfirm').addEventListener('click', () => {
                    setFormData({
                        name: '',
                        address: '',
                        longitude: 30,
                        latitude: 30,
                        gender: '',
                        type: '',
                        categoryId: 0,
                        isFree: 'UNKNOWN',
                        needKeyAccess: 'FALSE',
                        note: '',
                        facilitiesIds: [],
                        openHours: []
                    });
                    Swal.close();
                });

                document.getElementById('customCancel').addEventListener('click', () => {
                    Swal.close();
                });
            }
        });


    }
    // const convertTo24Hour = (timeStr) => {
    //     const [time, modifier] = timeStr?.split(' ');
    //     let [hours, minutes] = time?.split(':');

    //     if (modifier === 'PM' && hours !== '12') {
    //         hours = parseInt(hours, 10) + 12;
    //     } else if (modifier === 'AM' && hours === '12') {
    //         hours = '00'; 
    //     }

    //     return `${String(hours).padStart(2, '0')}:${minutes}`;
    // };

    return (
        <>
            <div className="mapAdd">
                <div className="overlay">
                    <LoadScript googleMapsApiKey="AIzaSyDSAZmbR3qq2lzzW1wmMohp-pgJ399LhHI">
                        <GoogleMap
                            className="mapp"
                            mapContainerStyle={mapContainerStyle}
                            center={selectedPosition || searchLocation}
                            zoom={zoom}
                            options={{
                                    styles: [
                                        {
                                            featureType: 'poi',
                                            stylers: [{ visibility: 'off' }],
                                        },
                                        {
                                            featureType: 'poi.park',
                                            stylers: [{ visibility: 'on' }],
                                        },
                                        {
                                            featureType: 'transit.station',
                                            stylers: [{ visibility: 'on' }],
                                        },
                                    ],
                                    disableDefaultUI: true,
                                }}
                            onClick={(e) => handleMapClick(e)}
                        >
                            {selectedPosition ? (
                                <>
                                    {console.log(selectedPosition)}
                                    <Marker
                                        position={selectedPosition}
                                        icon={{ url: '../assets/img/add/location.svg' }}
                                    />
                                </>

                            ) : (
                                <Marker
                                    position={initialCenter}
                                    icon={{ url: '../assets/img/add/location.svg' }}
                                />
                            )}
                        </GoogleMap>
                        <div className="map-controls">
                     
          
                     <button className="zoom-button" onClick={() => setZoom(zoom + 1)}>+</button>
                     <button className="zoom-button" onClick={() => setZoom(zoom - 1)}>-</button>
                 </div>
                    </LoadScript>
                </div>
            </div>
            <form className="container-add" onSubmit={(e) => handleSubmit(e)}>
                <div className="child">
                    <div className="child-01">
                        <div className="child0">
                            <div className="inputBox0">
                                <input type="text" style={{ paddingLeft: "10px" }} value={formData.address}
                                    onChange={handleInputChange} name="address" placeholder="Type the address here or search for it" />
                                <span>Address</span>
                            </div>
                            <div className="inputBox0">
                                <input type="text" value={formData.name}
                                    onChange={handleInputChange} style={{ paddingLeft: "10px" }} name="name" placeholder="Type name of Restroom here" />
                                <span>Restroom name</span>
                            </div>
                        </div>
                        <div className="child1">
                            <div className="child2">

                                <div className="title0">
                                    <input type="checkbox" checked={formData.gender === "ALL GENDER"}
                                        onChange={handleCheckboxChangeg} name="gender" id="ALLGENDER" value="ALL GENDER" />
                                    <label htmlFor="ALLGENDER">
                                        <img
                                            src="../assets/img/GenderIntersex.svg" alt="" />
                                        <h5>ALL GENDER</h5>
                                    </label>
                                </div>
                                <div className="title0">
                                    <input type="checkbox" checked={formData.gender === "WOMAN"}
                                        onChange={handleCheckboxChangeg} name="gender" id="Women" value="WOMAN" />
                                    <label htmlFor="Women">
                                        <img
                                            src="../assets/img/add/GenderFemale.svg" alt="" />
                                        <h5>Women</h5>
                                    </label>
                                </div>
                                <div className="title0">
                                    <input type="checkbox" checked={formData.gender === "MAN"}
                                        onChange={handleCheckboxChangeg} name="gender" id="man" value="MAN" />
                                    <label htmlFor="man">
                                        <img
                                            src="../assets/img/add/GenderMale.svg" alt="" />
                                        <h5>Man</h5>
                                    </label>
                                </div>


                            </div>
                            <span className="gender">Gender</span>
                        </div>

                    </div>
                    <div className='resCategories'>
                        <div className="Categories">
                            <div className="divb"><span>Categories</span></div>

                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange}
                                    value={1} id="gas"
                                    checked={formData.categoryId === 1}
                                />
                                <label htmlFor="gas">
                                    <img src="../assets/img/add/Vector (4).svg" alt="" />

                                    <h5>Gas Stations</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange}
                                    value={6} id="Restaurants"
                                    checked={formData.categoryId === 6}

                                />
                                <label htmlFor="Restaurants">
                                    <img src="../assets/img/add/Vector (5).svg" alt="" />

                                    <h5>Restaurants & Cafés</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange}
                                    value={14} id="Public"
                                    checked={formData.categoryId === 14}

                                />
                                <label htmlFor="Public">
                                    <img src="../assets/img/add/Vector (8).svg" alt="" />

                                    <h5>Public Buildings</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange}
                                    value={5} id="Restrooms"
                                    checked={formData.categoryId === 5}

                                />
                                <label htmlFor="Restrooms">
                                    <img src="../assets/img/add/Vector (9).svg" alt="" />

                                    <h5>Public Restrooms</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange}
                                    value={3} id="Grocery"
                                    checked={formData.categoryId === 3}

                                />
                                <label htmlFor="Grocery">
                                    <img src="../assets/img/add/Vector (10).svg" alt="" />

                                    <h5>Grocery Stores</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={2} id="Park"
                                    checked={formData.categoryId === 2}
                                />
                                <label htmlFor="Park">
                                    <img src="../assets/img/add/Vector (11).svg" alt="" />

                                    <h5>Park & Beach</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={12} id="Bars"
                                    checked={formData.categoryId === 12}
                                />
                                <label htmlFor="Bars">
                                    <img src="../assets/img/add/Vector (12).svg" alt="" />

                                    <h5>Bars</h5>
                                </label>
                            </div>

                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={7} id="Malls"
                                    checked={formData.categoryId === 7}

                                />
                                <label htmlFor="Malls">
                                    <img src="../assets/img/add/Vector (13).svg" alt="" />

                                    <h5>Malls</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={10} id="Gym"
                                    checked={formData.categoryId === 10}

                                />
                                <label htmlFor="Gym">
                                    <img src="../assets/img/add/Vector (20).svg" alt="" />

                                    <h5>Gym</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={8} id="BusTerminals"
                                    checked={formData.categoryId === 8}

                                />
                                <label htmlFor="BusTerminals">
                                    <img src="../assets/img/add/Vector (15).svg" alt="" />

                                    <h5>Bus Terminals</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={9} id="TrainStations"
                                    checked={formData.categoryId === 9}

                                />
                                <label htmlFor="TrainStations">
                                    <img src="../assets/img/add/Vector (16).svg" alt="" />

                                    <h5>Train Stations</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={11} id="Airports"
                                    checked={formData.categoryId === 11}

                                />
                                <label htmlFor="Airports">
                                    <img src="../assets/img/add/Vector (17).svg" alt="" />

                                    <h5>Airports</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} value={13} id="SubwayStations"
                                    checked={formData.categoryId === 13}

                                />
                                <label htmlFor="SubwayStations">
                                    <img src="../assets/img/add/Vector (18).svg" alt="" />

                                    <h5>Subway Stations</h5>
                                </label>
                            </div>
                            <div className="title">
                                <input type="radio" name="categoryId" onChange={handleCheckboxChange} id="Other" value={4}
                                    checked={formData.categoryId === 4}

                                />
                                <label htmlFor="Other">
                                    <img src="../assets/img/add/Vector (19).svg" alt="" />

                                    <h5>Other</h5>
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="divRestType">
                        <div className="restroomType">
                            <div className="type">
                                <input type="checkbox" name="type" checked={formData.type === "SINGLE"}
                                    onChange={handleCheckboxChangeg} value="SINGLE" id="single" />
                                <label htmlFor="single">
                                    <img
                                        src="../assets/img/add/Person (2).svg" alt="" />
                                    <h5>Single</h5>
                                </label>
                            </div>
                            <div className="type">
                                <input type="checkbox" name="type" checked={formData.type === "SHARED"}
                                    onChange={handleCheckboxChangeg} value="SHARED" id="Shared" />
                                <label htmlFor="Shared">
                                    <img
                                        src="../assets/img/add/Person (1).svg" alt="" />
                                    <h5>Shared</h5>
                                </label>
                            </div>
                        </div>

                        <div className="facilities">
                            <div className="type0">
                                <input type="checkbox" name="facility" id="chair0" value={4} onChange={handleFacilityChange}
                                    checked={formData.facilitiesIds.includes('4')}
                                />
                                <label htmlFor="chair0">
                                    <img src="../assets/img/Vector (4)b.svg" alt="" />
                                    <img className="active" alt="" src="../assets/img/Property 1=Frame 182 (4).svg" />

                                    <h5>Wheelchair</h5>
                                </label>
                            </div>
                            <div className="type0">
                                <input type="checkbox" name="facility" id="changing" value={1} onChange={handleFacilityChange}
                                    checked={formData.facilitiesIds.includes('1')}
                                />
                                <label htmlFor="changing">
                                    <img src="../assets/img/Vector (5)b.svg" alt="" />
                                    <img className="active" alt="" src="../assets/img/Property 1=Frame 182 (6).svg" />
                                    <h5>Baby changing</h5>
                                </label>
                            </div>
                            <div className="type0">
                                <input type="checkbox" name="facility" id="Bidet" value={2} onChange={handleFacilityChange}
                                    checked={formData.facilitiesIds.includes('2')}
                                />
                                <label htmlFor="Bidet">
                                    <img src="../assets/img/Vector (6)b.svg" alt="" />
                                    <img className="active" src="../assets/img/Property 1=Frame 182 (7).svg" alt="" />

                                    <h5>Bidet</h5>
                                </label>
                            </div>
                            <div className="type0">
                                <input type="checkbox" name="facility" id="Shower" value={3} onChange={handleFacilityChange}
                                    checked={formData.facilitiesIds.includes('3')}
                                />
                                <label htmlFor="Shower" >
                                    <img src="../assets/img/Vector (7)b.svg" alt="" />
                                    <img className="active" style={{ filter: "invert(1) brightness(4)" }} src="../assets/img/Vector (7)b.svg" alt="" />
                                    <h5>Shower</h5>
                                </label>
                            </div>
                        </div>

                        <div className="restroomm"><span>Restroom type</span></div>
                        <div className="facilitiess"><span>Facilities</span></div>

                    </div>

                    <div className="line">
                        <div className="line0"></div>
                    </div>

                    <div className="parent">

                        <div className="centerr">

                            <div className="child00">
                                <div className="kl d-flex align-items-center">
                                    <img src="../assets/img/dollar.svg" />
                                    <span>Free</span>
                                    {/* <input className="do0" type="radio" name="free" value="yes" /> */}
                                    <div className="container121">


                                        <label className="toggle-check">
                                            <input type="checkbox" className="toggle-check-input"
                                                name="isFree"
                                                value="TRUE"
                                                checked={formData.isFree === 'TRUE'}
                                                onChange={() => handleRadioChange('isFree', 'TRUE')} />
                                            <span className="toggle-check-text"></span>
                                            <span className="toggle-check-textt"></span>

                                        </label>
                                    </div>

                                </div>
                                <div></div>
                                <div className="do">
                                    <div> Don’t know? </div>
                                    <div >
                                        <input className="do0" type="checkbox" id='free' name="isFree"
                                            value="UNKNOWN"
                                            checked={formData.isFree === 'UNKNOWN'}
                                            onChange={() => handleRadioChange('isFree', 'UNKNOWN')} />
                                        <label htmlFor="free">
                                            <img src="../assets/img/Property 1=Frame 402.svg" alt="" />
                                            <img className='active' src="../assets/img/Property 1=Frame 403.svg" alt="" />

                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="do00"><hr /></div>
                            <div className="child01">
                                <div className="kl  d-flex align-items-center">
                                    <img src="../assets/img/locker.svg" />

                                    <span className='kspan'>Need Key access</span>
                                    <div className="container121 keyleft">


                                        <label className="toggle-check">
                                            <input type="checkbox" className="toggle-check-input" name="needKeyAccess"
                                                value="TRUE"
                                                checked={formData.needKeyAccess === 'TRUE'}
                                                onChange={() => handleRadioChange('needKeyAccess', 'TRUE')} />
                                            <span className="toggle-check-text"></span>
                                            <span className="toggle-check-textt"></span>

                                        </label>
                                    </div>
                                </div>


                                <abbr className='warni tooltippppy' style={{ marginLeft: "20px" }}>
                                    <img src="../assets/img/warn.svg" alt='Warning' />
                                    <span class="tooltiptext">This restroom may need an exclusive access or membership to enter</span>
                                </abbr>

                                <div className="do">
                                    <div> Don’t know? </div>
                                    <div className="do0">
                                        <input className="do0" type="checkbox" id='needKeyAccess' name="needKeyAccess"
                                            value="UNKNOWN"
                                            checked={formData.needKeyAccess === 'UNKNOWN'}
                                            onChange={() => handleRadioChange('needKeyAccess', 'UNKNOWN')} />
                                        <label htmlFor="needKeyAccess">
                                            <img src="../assets/img/Property 1=Frame 402.svg" alt="" />
                                            <img className='active' src="../assets/img/Property 1=Frame 403.svg" alt="" />

                                        </label>
                                    </div>
                                </div>




                            </div>

                        </div>
                    </div>

                    <div className="line1">
                        <div className="line2"></div>
                    </div>
                    <div className="timee">
                    <div className="timeline">
                            <div className="kl01">
                                <img src="../assets/img/Clock.svg" />

                                <span>Do you know the opening hours?</span>
                            </div>
                            <div className="container1211">


                                <label className="toggle-check">
                                    <input type="checkbox" className="toggle-check-input" onClick={toggleTimeline} id="checkbox" />
                                    <span className="toggle-check-text"></span>
                                    <span className="toggle-check-textt"></span>

                                </label>
                            </div>
                        </div>

                        {isTimelineVisible && (
                            <div className="checkbox78" id="timeline4">
                                <div className="typegraphy01">
                                    <div className="dis">
                                        <span>Monday</span>
                                        <span>Tuesday</span>
                                        <span>Wednesday</span>
                                        <span>Thursday</span>
                                        <span>Friday</span>
                                        <span>Saturday</span>
                                        <span>Sunday</span>
                                    </div>
                                    <div className="ldi">
                                        {/* <div className="container1211">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox0"
                                                 
                                                />
                                                <label className="switch0"
                                                    <span className="slider0"></span>
                                                </label>
                                            </div> */}
                                        {Object.keys(dayTimes)?.map((key, index) => (
                                            <div className="container1211" key={index}>
                                                <label className="toggle-check" htmlFor={`checkbox${index}`}>
                                                    <input type="checkbox" className="toggle-check-input"
                                                        checked={dayTimes[key]}
                                                        onChange={() => toggleDayTime(key)}
                                                        id={`checkbox${index}`} />
                                                    <span className="toggle-check-text"></span>
                                                    <span className="toggle-check-textt"></span>

                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="timme">
                                        {Object.keys(dayTimes)?.map((key, index) => (
                                            <div key={index}>
                                                {dayTimes[key] ?
                                                    <div className="d-flex justify-content-around w-100">
                                                        <input
                                                            type="time"
                                                            className={key}
                                                            name={`startTime${index}`}
                                                            placeholder="Start Time"
                                                        />
                                                        <span >to </span>
                                                        <input
                                                            type="time"
                                                            className={key}
                                                            name={`endTime${index}`}
                                                            placeholder="End Time"
                                                        />
                                                    </div>
                                                    : <span className='close'>Close</span>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="child-01">
                            <div className="child0">
                                <div className="inputBox0">
                                    <textarea type="text" name="note" value={formData.note}
                                        onChange={handleInputChange} style={{ paddingLeft: "10px" }} placeholder="Add any other useful information about the restroom here" ></textarea>
                                    <span>Notes</span>
                                </div>

                            </div>
                        </div>
                        <div className="child">
                            <div className="photos">
                                <input type="file" accept="image/*" multiple onChange={handlePhotoUpload} style={{ visibility: "hidden" }} id='filePhoto' />
                                <label htmlFor="filePhoto" className='labelphoto'>
                                    <div className="add-photo-alternate-parent">
                                        <img className="add-photo-alternate-icon" alt="" src="../assets/img/add_photo_alternate.svg" />
                                        <div className="add-photos">Add photos</div>
                                    </div>
                                </label>
                                <div className="photo-previews">
                                    {photoPreviews?.map((preview, index) => (
                                        <span className='photoaddx'>
                                            <img key={index} src={preview} alt={`preview-${index}`} className="photo-preview" />
                                            <button className="remove-photo-button" onClick={() => handleRemovePhoto(index)}>
                                                &times;
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="group-parent">
                            <img className="frame-child" alt="" src="Group 5.svg" />

                        </div>

                        <div className="frame-parent d-flex justify-content-center">
                            <button type="button" className="cancel-parent" id="frameContainer" onClick={cancelform}>
                                <div className="cancel">Cancel</div>
                            </button>
                            <button type="submit" className="save-parent" id="frameContainer1">
                                Save
                            </button>
                        </div>

                    </div>

                </div>


            </form>
            <div className="scroll-to-top" onClick={scrollToTop}>
                <IoIosArrowDropup size={30} color="#007bff" />
            </div>
            <Footer />
        </>
    )
}

export default UpdateRoom



