import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { DirectionsRenderer } from '@react-google-maps/api';
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';
import Swal from "sweetalert2";
import Reviews from "./Reviews";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const mapContainerStyle = {
    width: '100%',
    height: '100%',
};

const initialCenter = {
    lat: 40.712776,
    lng: -74.005974
};

const Home = () => {
    const location = useLocation();
    let locationrestroom = location.state?.selectedRoom ? location.state?.selectedRoom : null

    const [searchLocation, setSearchLocation] = useState(initialCenter);
    const [currentDate, setCurrentDate] = useState(new Date());
    const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
    const currentTime = currentDate.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
    const [isActive0, setIsActive0] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedValue, setSelectedValue] = useState('Select');
    const [selectedValue1, setSelectedValue1] = useState('Select');
    const [selectedValue2, setSelectedValue2] = useState(0);
    const [selectedValue3, setSelectedValue3] = useState('Select');
    const [filteredRestrooms, setFilteredRestrooms] = useState([]);
    const [selectedRestroom, setSelectedRestroom] = useState(locationrestroom ? locationrestroom : null);
    const [profileUrl, setProfileUrl] = useState("./assets/img/profile.png");
    const [name, setName] = useState("");
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(initialCenter);
    const [checkboxes, setCheckboxes] = useState({ isOpen: false, isFree: false, needKeyAccess: false });
    const [favorites, setFavorites] = useState([]);
    const [isFavorited, setIsFavorited] = useState({});
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [directionMode, setDirectionMode] = useState('DRIVING');
    const [filteredRestroomsempty, setFilteredRestroomsempty] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [results, setResults] = useState(false);
    const [totalResults, setTotalResults] = useState(0);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                ({ coords }) => setCurrentLocation({ lat: coords.latitude, lng: coords.longitude }),
                (error) => console.error('Error fetching user location:', error)
            );
        }
    }, []);

    useEffect(() => {
        if (selectedRestroom && windowidth <= 1024 && location.pathname === '/' || locationrestroom && windowidth <= 1024 && location.pathname === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            document.body.style.height = "100vh"
            document.body.style.overflowY = "hidden"
        }
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('authToken');
        if (userId) {
            axios.get(`https://api.rerests.com/api/v1/user/${userId}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            })
                .then(response => {
                    setName(response.data.data.name);
                    if (response.data.data.image) {
                        setProfileUrl(response.data.data.image);
                    }
                })
                .catch(error => console.error("Error fetching user data", error));
        }
    }, []);

    const toggleCheckbox = (event) => {
        const { name } = event.target;
        setCheckboxes(prev => ({ ...prev, [name]: !prev[name] }));
    };

    const handleMapClick = useCallback((event) => {
        setSelectedPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
        setCurrentLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });

    }, []);

    useEffect(() => {
        const fetchRestroomData = async () => {
            if (location.state?.selectedRoom) {
                try {
                    const response = await fetch(`https://api.rerests.com/api/v1/rest-room/${location.state.selectedRoom.id}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    const alldata = data.data;

                    const restroomsToDisplay = Array.isArray(alldata) ? alldata : [alldata];

                    setFilteredRestrooms(restroomsToDisplay);

                } catch (error) {
                    console.error('Error fetching restroom data:', error);
                    setFilteredRestrooms([]);
                }
            } else {
                setFilteredRestrooms([]);
            }
        };

        fetchRestroomData();
    }, [location.state]);


    const fetchLocations = useCallback(async () => {
        const authToken = localStorage.getItem("authToken");
        try {
            const response = await axios.get('https://api.rerests.com/api/v1/rest-room?page=1&&take=50', {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response) {
                setLocations(response.data.data.rooms);
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }, []);

    const handleMoreDetailsClick = async (restroom) => {
        try {
            const response = await fetch(`https://api.rerests.com/api/v1/rest-room/${restroom.id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch restroom details');
            }
            const restroomDetails = await response.json();
            setSelectedRestroom(restroomDetails.data);
            if (windowidth <= 1024) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (location.pathname === '/') {
                    document.body.style.height = '100vh'
                    document.body.style.overflowY = 'hidden';
                }

            }

        } catch (error) {
            setSelectedRestroom(restroom)
        }
    };

    const handleAddToFavorite = async (restRoomId) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                const method = isFavorited[restRoomId] ? 'DELETE' : 'POST';
                const response = await fetch(`https://api.rerests.com/api/v1/user/${restRoomId}/favorite`, {
                    method, headers: { 'Authorization': `Bearer ${authToken}` }
                });
                if (response.ok) {
                    setIsFavorited(prev => ({ ...prev, [restRoomId]: !isFavorited[restRoomId] }));
                    if (isFavorited[restRoomId]) {
                        Swal.fire({
                            title: 'Removed from Favorite',
                            icon: 'success',
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            title: 'Added to Favorite',
                            icon: 'success',
                            timer: 1500
                        });
                    }
                }
            } else {
                Swal.fire({
                    title: 'Please Sign In',
                    icon: 'error',
                    timer: 1500
                });
            }

        } catch (error) {
            Swal.fire({
                title: 'Something Went Wrong',
                icon: 'error',
                timer: 1500
            });
        }
    };


    const displayOpenHours = (openHours) => {
        const daysOrder = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
        return daysOrder.map((day, index) => {
            const hour = openHours.find(h => h.day === day);
            return (
                <div key={hour?.id || index} className="frame-parent12">
                    <div className="wrapper">
                        <div className="manhattan">{hour?.day}</div>
                    </div>
                    <div className="frame">
                        <div className="manhattan">{hour?.isOpen ? `${hour.startTime.slice(0, 5)} - ${hour.endTime.slice(0, 5)}` : 'Closed'}</div>
                    </div>
                </div>
            );
        });
    };
    const handleGetDirections = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                const origin = { lat: coords.latitude, lng: coords.longitude };
                const destination = { lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) };

                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route({ origin, destination, travelMode: directionMode }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result);
                    } else {
                        console.error('Error fetching directions', status);
                    }
                });
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, [selectedRestroom, directionMode]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error('Error fetching user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);
    const handleChange = (event) => {
        const { name } = event.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    useEffect(() => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('authToken');
        if (userId) {
            axios.get(`https://api.rerests.com/api/v1/user/${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                    setName(response.data.data.name);
                    if (response.data.data.image) {
                        setProfileUrl(response.data.data.image);

                    }

                })
                .catch(error => {
                    console.error("Error fetching user data", error);
                });
        }
    }, []);


    const hideshow = () => {

        setShowFilter(!showFilter);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleCategoryChange = (event) => {
        const { value, checked } = event.target;
        setSelectedCategories(prevCategories =>
            checked ? [...prevCategories, parseInt(value)] : prevCategories.filter(category => category !== parseInt(value))
        );
    };
    const handleFacilityChange = (event) => {
        const { value, checked } = event.target;
        setSelectedFacilities(prevFacilities =>
            checked ? [...prevFacilities, parseInt(value)] : prevFacilities.filter(facility => facility !== parseInt(value))
        );
    };


    useEffect(() => {
        const fetchFavorites = async () => {
            try {

                const authToken = localStorage.getItem('authToken');
                if (authToken) {
                    const response = await fetch('https://api.rerests.com/api/v1/user/favorites', {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    });
                    const data = await response.json();
                    setFavorites(data.data);

                    const favoritedIds = data.data.reduce((acc, favorite) => {
                        acc[favorite.restRoomId] = true;
                        return acc;
                    }, {});

                    setIsFavorited(favoritedIds);
                }

            } catch (error) {
                console.error('Error fetching favorites:', error);
            }
        };

        fetchFavorites();
    }, []);

    useEffect(() => {
        const removeInfoWindow = () => {
            document.querySelectorAll('div.gm-style-iw-chr').forEach(el => el.remove());
        };
        removeInfoWindow();
        const observer = new MutationObserver(removeInfoWindow);
        observer.observe(document.body, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, []);
    const [locations, setLocations] = useState([]);


    const [selectedMarker, setSelectedMarker] = useState(null);

    useEffect(() => {
        fetchLocations();
    }, []);

    const windowidth = window.innerWidth

    const handleMarkerMouseOver = (restroom) => {
        handleMarkerMouseOut()
        setSelectedMarker(restroom);
    };

    const handleMarkerMouseOut = () => {
        setSelectedMarker(null);
        const removeInfoWindow = () => {
            document.querySelectorAll('.gm-style-iw-a').forEach(el => el.remove());
            document.querySelectorAll('.gm-style-iw-d').forEach(el => el.remove());
        };

        removeInfoWindow();
    };

    const [isActivee, setIsActivee] = useState(false);

    const toggleClass = () => {
        setIsActivee(!isActivee);
    };

    const [isActivem, setIsActivem] = useState(false);

    const toggleClassm = () => {
        setIsActivem(!isActive);
    };
    const [activeButton, setActiveButton] = useState('cards');

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [currentTop, setCurrentTop] = useState(50);
    const tabDivRef = useRef(null);

    const handleDragStart = (e) => {

        setIsDragging(true);
        const clientY = e.type === 'mousedown' ? e.clientY : e.touches[0].clientY;
        setStartY(clientY);
        if (location.pathname === '/') {

            document.body.style.height = '100vh'
            document.body.style.overflowY = 'hidden';
        }
        const top = tabDivRef.current.getBoundingClientRect().top;
        setCurrentTop(top);
    };

    const handleDragMove = (e) => {
        if (!isDragging) return;
        if (location.pathname === '/') {

            document.body.style.height = '100vh'

            document.body.style.overflowY = 'hidden';
        }
        const clientY = e.type === 'mousemove' ? e.clientY : e.touches[0].clientY;
        const newY = clientY - startY + currentTop;

        if (window.innerWidth > 700) {
            if (tabDivRef.current) {
                if (newY <= -30) {
                    tabDivRef.current.style.top = `-30px`;
                    setResults(false);
                } else if (newY >= 700.8017) {
                    tabDivRef.current.style.top = `650.8017px`;
                    setResults(true);
                } else {
                    tabDivRef.current.style.top = `${newY}px`;
                }
            }
        } else {
            if (tabDivRef.current) {
                if (newY <= 30) {
                    tabDivRef.current.style.top = `30px`;
                    setResults(false);
                } else if (newY >= 600.8017) {
                    tabDivRef.current.style.top = `550.8017px`;
                    setResults(true);
                } else {
                    tabDivRef.current.style.top = `${newY}px`;
                }
            }
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);

    };

    useEffect(() => {
        window.addEventListener('mousemove', handleDragMove);
        window.addEventListener('mouseup', handleDragEnd);
        window.addEventListener('touchmove', handleDragMove);
        window.addEventListener('touchend', handleDragEnd);

        return () => {
            window.removeEventListener('mousemove', handleDragMove);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchmove', handleDragMove);
            window.removeEventListener('touchend', handleDragEnd);
        };
    }, [isDragging, startY, currentTop]);

    useEffect(() => {
        if (tabDivRef.current) {
            tabDivRef.current.style.top = '450px';
            setCurrentTop(50);
        }
    }, [selectedRestroom]);

    const handleSwiper = useCallback((swiperInstance) => {
        setThumbsSwiper(swiperInstance);
    }, []);

    const handleReset = () => {
        setCurrentDate(new Date());
        setIsActive0(false);
        setIsActive(false);
        setIsActive2(false);
        setSelectedCategories([]);
        setSelectedFacilities([]);
        setShowFilter(false);
        setSearchTerm('');
        setSelectedValue('Select');
        setSelectedValue1('Select');
        setSelectedValue2(0);
        setSelectedValue3('Select');
        setSelectedPosition(null);
        // setCurrentLocation(initialCenter);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error('Error fetching user location:', error);
                }
            );
        }
        setCheckboxes({ isOpen: false, isFree: false, needKeyAccess: false, nearby: false });

        const checkboxes = document.querySelectorAll('input[type="checkbox"], input[type="radio"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
    };
    const viewDrag = () => {
        if (tabDivRef.current) {
            tabDivRef.current.style.top = '50px';
            setCurrentTop(50);
        }
    }
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [photoPreviews, setPhotoPreviews] = useState([]);
    const [photos, setPhotos] = useState([]);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handlePhotoUpload = (e) => {
        const files = Array.from(e.target.files);
        const newPhotosPromises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    // Extract Base64 part from the full Data URL
                    const base64Data = base64String.split(',')[1];
                    resolve(base64Data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newPhotosPromises).then((newPhotos) => {
            setPhotoPreviews(prev => [...prev, ...newPhotos.map(data => `data:image/png;base64,${data}`)]);
            setPhotos(prev => [...prev, ...newPhotos]);
        }).catch(error => {
            console.error('Error reading files:', error);
        });
    };
    const handleSubmit = async (e, restroomId) => {
        e.preventDefault();

        const token = localStorage.getItem("authToken")
        if (!token) {

            Swal.fire({
                title: 'Please Sign In',
                icon: 'error',
                timer: 1500
            });
        } else {
            try {

                await axios.patch(`https://api.rerests.com/api/v1/rest-room/${restroomId}/photo`, {
                    url: photos[0],
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                closeModal()
                Swal.fire({
                    title: 'Photo is Added',
                    icon: 'success',
                    timer: 1500
                })

            } catch (error) {
                console.error('Error uploading photo:', error);
            }
        }

    };

    const [currentPage, setCurrentPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const handleFilterChange = async (page = 1) => {
        setSelectedRestroom(null);
        const token = localStorage.getItem("authToken");
        document.body.style.height = 'unset'
        document.body.style.overflowY = 'unset';
        const params = {
            page,
            take: 50,
            latitude: checkboxes.nearby ? currentLocation?.lat : selectedPosition?.lat,
            longitude: checkboxes.nearby ? currentLocation?.lng : selectedPosition?.lng,
            name: searchTerm.toLowerCase(),
            type: selectedValue1 !== 'Select' ? selectedValue1 : undefined,
            gender: selectedValue === 'male' ? selectedValue : undefined,
            needKeyAccess: checkboxes.needKeyAccess ? 'TRUE' : 'UNKNOWN',
            isFree: checkboxes.isFree ? 'TRUE' : 'UNKNOWN',
            isOpen: checkboxes.isOpen ? true : 'UNKNOWN',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            dayNumber: new Date().getDay(),
            categoryIds: selectedCategories.length ? JSON.stringify(selectedCategories) : undefined,
            facilitiesIds: selectedFacilities.length ? JSON.stringify(selectedFacilities) : undefined,
        };

        const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined));

        try {
            const response = await fetch(`https://api.rerests.com/api/v1/rest-room?${new URLSearchParams(filteredParams)}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setFilteredRestrooms(data.data.rooms);

            setCurrentPage(page);
            setHasNextPage(data.data.rooms.length === params.take);
            setFilteredRestroomsempty(data.data.rooms.length === 0);
            setTotalResults(data.data.totalData)

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (location.state?.selectedRoom) {
            navigate(location.pathname, { state: { selectedRestroom: null } });
        }
        document.body.style.height = 'unset'
        document.body.style.overflowY = 'unset';

    };

    useEffect(() => {
        if (location.pathname != '/') {
            document.body.style.height = 'unset';
            document.body.style.overflowY = 'unset';
        }
    }, [location.pathname]);

    return (
        <>
            <div className={`containerbody d0 ${filteredRestroomsempty || filteredRestrooms.length > 0 || locationrestroom ? 'active' : null} ${activeButton === 'map' ? 'mape' : null}`}>
                <div>
                    <div className={`overlay ${filteredRestroomsempty || filteredRestrooms.length > 0 || selectedRestroom ? 'filtered' : null}`}>
                        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLEMAPKEY}>
                            <GoogleMap

                                mapContainerStyle={mapContainerStyle}
                                center={
                                    selectedRestroom
                                        ? { lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) }
                                        : (filteredRestrooms.length > 0
                                            ? { lat: parseFloat(filteredRestrooms[0].latitude), lng: parseFloat(filteredRestrooms[0].longitude) }
                                            : currentLocation
                                                ? currentLocation
                                                : searchLocation)
                                }
                                zoom={filteredRestrooms[0] && !selectedRestroom ? 6 : 15}
                                onClick={(e) => handleMapClick(e)}
                                options={{
                                    styles: [
                                        {
                                            featureType: 'poi',
                                            stylers: [{ visibility: 'off' }],
                                        },
                                        {
                                            featureType: 'poi.park',
                                            stylers: [{ visibility: 'on' }],
                                        },
                                        {
                                            featureType: 'transit.station',
                                            stylers: [{ visibility: 'on' }],
                                        },
                                    ],
                                    // disableDefaultUI: true,
                                }}

                            >
                                {currentLocation && (
                                    <>
                                        <Marker
                                            position={currentLocation}
                                            icon={{ url: './assets/img/Logo_Header.svg' }}
                                        />
                                    </>

                                )}
                                {selectedPosition && !selectedRestroom && (
                                    <Marker
                                        position={selectedPosition}
                                        icon={{ url: './assets/img/Logo_Header.svg' }}
                                    />
                                )}

                                {!selectedRestroom && filteredRestrooms.map((restroom, index) => (
                                    <Marker
                                        key={`filtered-${index}`}
                                        position={{ lat: parseFloat(restroom.latitude), lng: parseFloat(restroom.longitude) }}
                                        onClick={() => handleMoreDetailsClick(restroom)}
                                        icon={{ url: './assets/img/Logo_Header.svg' }}
                                    />
                                ))}
                                {!selectedRestroom && locations.length > 0 && locations.map((location, index) => (
                                    <Marker
                                        key={`location-${index}`}
                                        position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
                                        onClick={() => handleMarkerMouseOver(location)}
                                        icon={{ url: './assets/img/Logo_Header.svg' }}
                                    >
                                        {selectedMarker === location && (
                                            <InfoWindow
                                                position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
                                                onCloseClick={() => setSelectedMarker(null)}
                                                width={100}
                                                height={300}
                                            >
                                                <Col md="12" lg="12" sm="12" className="cardrestroom map" key={location.id} >
                                                    <div className="rectangle-parent">

                                                        <Swiper
                                                            spaceBetween={10}
                                                            slidesPerView={1}
                                                            pagination={{ clickable: true }}
                                                            autoplay={{ delay: 3000 }}
                                                            className="photo-swiper"
                                                            navigation={true}
                                                            modules={[FreeMode, Navigation, Pagination]}

                                                        >
                                                            {location.photos?.length == 0 ?
                                                                <SwiperSlide>
                                                                    <img src="assets/img/noimg.png" alt="Restroom" className="restroom-photo" />
                                                                </SwiperSlide>
                                                                :
                                                                location.photos.map(photo => (
                                                                    <SwiperSlide key={photo.id}>
                                                                        <img src={photo.url} alt="Restroom" className="restroom-photo" />
                                                                    </SwiperSlide>
                                                                ))}

                                                        </Swiper>
                                                        <div className="frame-parent">
                                                            <div className="frame-group d-flex justify-content-between flex-wrap">
                                                                <div className="manx-museum-wrapper">
                                                                    <div
                                                                        className={`manx-museum ${location.name.length > 15 ? 'hoverexceed' : ''} `}
                                                                        title={location.name.length > 15 ? location.name : ''} data-fullname={location.name.length > 15 ? location.name : ''}
                                                                    >
                                                                        {location.name.length > 15 ? `${location.name.slice(0, 15)}...` : location.name}
                                                                    </div>
                                                                </div>
                                                                <div className="frame-wrapper ml-auto">
                                                                    <div className="parent">
                                                                        <div className="ratings" id="ratingsText">
                                                                            <span className="ratings-txt-container">
                                                                                <span className="ratings1">({location.avgRating}) ratings</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="frame-div justify-content-between">
                                                                <div className="closed-parent">
                                                                    {location.openHours?.some(hour =>
                                                                        hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                    ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div">Closed</div>}
                                                                </div>
                                                                <div className="more-details-wrapper" id="frameContainer1">
                                                                    <div className="more-details" onClick={() => handleMoreDetailsClick(location)}>More details</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </InfoWindow>
                                        )}
                                    </Marker>
                                ))}

                                {selectedRestroom && (
                                    <>
                                        <Marker
                                            position={{ lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) }}
                                            icon={{ url: './assets/img/Logo_Header.svg' }}
                                        />
                                        {directionsResponse && (
                                            <DirectionsRenderer directions={directionsResponse} />
                                        )}
                                    </>
                                )}
                            </GoogleMap>
                  
                        </LoadScript>

                    </div>
                    {windowidth > 1025 ?
                        <div className={`searchbar`}>
                            <div className="frame-parent">
                                <div className="frame-group">
                                    <div className="frame-container">
                                        <div className="frame-wrapper tabwrapper">
                                            <div className="frame-div">
                                                <input placeholder="Search for restroom"
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                    name="searchTerm"
                                                    className="search-for-restroom-wrapper" />
                                                <input type="checkbox" name="nearby" id="nearby" checked={checkboxes.nearby}
                                                    onChange={handleChange}
                                                />
                                                <label className="gislocation-poi-o-parent" htmlFor="nearby">
                                                    <img className="gislocation-poi-o-icon" alt="" src="./assets/img/gis_location-poi-o.svg" />
                                                    <img className="gislocation-poi-o-icon checked" alt="" src="./assets/img/gis_location-poi-o-checked.svg" />



                                                    <div className="search-for-restroom">Nearby</div>
                                                </label>
                                            </div>
                                        </div>
                                        <img className="linevertical-icon" alt="" src="./assets/img/LineVertical.svg" />

                                    </div>
                                    <div className="frame-parent1">
                                        <input type="checkbox" name="isOpen" id="Open" checked={checkboxes.isOpen}
                                            onChange={handleChange}
                                        />

                                        <label className="component-12-parent" htmlFor="Open">
                                            <img className="component-12-icon" alt="" src="./assets/img/search/Property.svg" />
                                            <img className="component-12-icon active" alt="" src="./assets/img/Property 1=Frame 182 (5).svg" />

                                            <div className="search-for-restroom">Open now</div>
                                        </label>
                                        <input type="checkbox" name="chair" id="chair" value={4} onChange={handleFacilityChange} />
                                        <label className="component-12-parent" htmlFor="chair">
                                            <img className="component-12-icon" alt="" src="./assets/img/search/chair.svg" />
                                            <img className="component-12-icon active" alt="" src="./assets/img/Property 1=Frame 182 (4).svg" />

                                            <div className="frame-container">
                                                <div className="search-for-restroom">Wheelchair</div>
                                            </div>
                                        </label>
                                        <input type="checkbox" name="Baby" id="Baby" value={1} onChange={handleFacilityChange} />
                                        <label htmlFor="Baby" className="component-12-parent">
                                            <img className="component-12-icon" alt="" src="./assets/img/search/Property 1=Frame 183 (2).svg" />
                                            <img className="component-12-icon active" alt="" src="./assets/img/Property 1=Frame 182 (6).svg" />

                                            <div className="search-for-restroom">Baby changing</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="frame-parent2">
                                    <button className="filters-parent" type="button" id="frameContainer" onClick={hideshow}>
                                        <div className="search-for-restroom">
                                            <div className="desk">
                                                Filters

                                                <img className="funnelsimple-icon" alt="" src="./assets/img/FunnelSimple.svg" />

                                            </div>
                                            <img src="./assets/img/sliders-horizontal.svg" className="mob" />
                                        </div>
                                    </button>

                                    <button onClick={() => { handleFilterChange(); }} className="search-parent" id="frameContainer1">

                                        <div className="search">Search</div>
                                        <img className="magnifyingglass-icon" alt="" src="./assets/img/MagnifyingGlass.svg" />
                                    </button>
                                </div>
                            </div>

                            <div className="pofilter" style={{ display: showFilter ? "block" : "none" }}>
                                <div id="filterContainer" className="popup-overlay" >
                                    <div className="filter">
                                        <div className="component-2-parent">
                                            <div className={`component-2 dropdown0 ${isActive0 ? 'active' : ''}`} onClick={() => setIsActive0(!isActive0)}>
                                                <div className="component-2-child">
                                                </div>
                                                <div className="component-2-item">
                                                </div>
                                                <div className="gender">Gender</div>
                                                <div className="select-parent">
                                                    <div className="select">{selectedValue ? selectedValue : 'Select'}</div>
                                                    <div className="caretdown">
                                                    </div>
                                                    <img className="vector-icon" alt="" src="./assets/img/search/Vector (21).svg" />

                                                </div>
                                                <ul className="options">
                                                    <li className="option" onClick={() => setSelectedValue('ALL GENDER')} >
                                                        <img
                                                            src="./assets/img/GenderIntersex.svg" alt="" />
                                                        <span className="option-test">ALL GENDER</span>
                                                    </li>
                                                    <li className="option" onClick={() => setSelectedValue('WOMEN')}>
                                                        <img
                                                            src="./assets/img/search/GenderFemale.svg" alt="" />
                                                        <span className="option-test" style={{ paddingLeft: "5px" }}>Women</span>
                                                    </li>
                                                    <li className="option" onClick={() => setSelectedValue('MAN')} >
                                                        <img
                                                            src="./assets/img/search/GenderMale.svg" alt="" />
                                                        <span className="option-test">Man</span>

                                                    </li>



                                                </ul>
                                            </div>

                                            <div className={`component-3 restroom-select ${isActive ? 'active' : ''}`} onClick={() => setIsActive(!isActive)}>
                                                <div className="component-2-child">
                                                </div>
                                                <div className="select-group">
                                                    <div className="select">{selectedValue1 ? selectedValue1 : 'Select'}</div>
                                                    <div className="caretdown">
                                                    </div>
                                                    <img className="vector-icon" alt="" src="./assets/img/search/Vector (21).svg" />

                                                </div>
                                                <div className="component-3-item">
                                                </div>
                                                <div className="restroom-type">Restroom Type</div>
                                                <ul className="options0">
                                                    <li className="option0" onClick={() => setSelectedValue1('SINGLE')}>
                                                        <img
                                                            src="./assets/img/search/Person.svg" alt="" />
                                                        <span className="option-testRoom" style={{ paddingLeft: "4px" }}>Single</span>
                                                    </li>
                                                    <li className="option0" onClick={() => setSelectedValue1('SHARED')}>
                                                        <img
                                                            src="./assets/img/UsersThree.svg" alt="" />

                                                        <span className="option-testRoom">Shared</span>
                                                    </li>

                                                </ul>

                                            </div>
                                            <div className={`component-4 categories-select ${isActive2 ? 'active' : ''}`} onClick={() => setIsActive2(!isActive2)} >
                                                <div className="component-2-child">
                                                </div>
                                                <div className="select-group">
                                                    <div className="select">{selectedValue3 ? selectedValue3 : 'Select'}</div>
                                                    <div className="caretdown">
                                                    </div>
                                                    <img className="vector-icon" alt="" src="./assets/img/search/Vector (21).svg" />

                                                </div>
                                                <div className="component-4-item">
                                                </div>
                                                <div className="categories">Categories</div>

                                                <ul className="options1">
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('1');
                                                        setSelectedValue3('Gas Stations');

                                                    }}
                                                    >
                                                        <img src="./assets/img/category/Vector (47).svg" alt="" />
                                                        <span className="option-Categories">Gas Stations</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('6'); setSelectedValue3('Restaurants & Cafés');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (48).svg" alt="" />
                                                        <span className="option-Categories">Restaurants & Cafés</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('0'); setSelectedValue3('Public Buildings');
                                                    }}>
                                                        <img src="./assets/img/category/Buildings.svg" alt="" />
                                                        <span className="option-Categories">Public Buildings</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('5'); setSelectedValue3('Public Restroom');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (49).svg" alt="" />
                                                        <span className="option-Categories">Public Restroom</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('3'); setSelectedValue3('Grocery Stores');
                                                    }}>
                                                        <img src="./assets/img/category/ShoppingCart.svg" alt="" />
                                                        <span className="option-Categories">Grocery Stores</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('2'); setSelectedValue3('Park & Beach');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (50).svg" alt="" />
                                                        <span className="option-Categories"> Park & Beach</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('12'); setSelectedValue3('Bars');
                                                    }}>
                                                        <img src="./assets/img/category/Cheers.svg" alt="" />
                                                        <span className="option-Categories"> Bars</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('7'); setSelectedValue3('Malls');
                                                    }}>
                                                        <img src="./assets/img/category/Building.svg" alt="" />
                                                        <span className="option-Categories"> Malls</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('10'); setSelectedValue3('Gym');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (51).svg" alt="" />
                                                        <span className="option-Categories"> Gym</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('8'); setSelectedValue3('Bus Terminals');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (52).svg" alt="" />
                                                        <span className="option-Categories"> Bus Terminals</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('9'); setSelectedValue3('Train Stations');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (53).svg" alt="" />
                                                        <span className="option-Categories"> Train Stations</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('11'); setSelectedValue3('Airports');
                                                    }}>
                                                        <img src="./assets/img/category/Airplane.svg" alt="" />
                                                        <span className="option-Categories"> Airports</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('13'); setSelectedValue3('Subway Stations');
                                                    }}>
                                                        <img src="./assets/img/category/Tram.svg" alt="" />
                                                        <span className="option-Categories"> Subway Stations</span>
                                                    </li>
                                                    <li className="option1" onClick={() => {
                                                        setSelectedValue2('4'); setSelectedValue3('Other');
                                                    }}>
                                                        <img src="./assets/img/category/Vector (55).svg" alt="" />
                                                        <span className="option-Categories"> Other</span>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <button type="reset" className="hover" onClick={handleReset}>
                                            <div className="reset-wrapper">
                                                <div className="reset">Reset</div>
                                            </div>
                                            <abbr className="clockclockwise tooltippppy" >
                                                <img src="./assets/img/search/Vector2.svg" alt="" />
                                                <span class="tooltiptext">This restroom may need an exclusive access or membership to enter</span>

                                            </abbr>
                                        </button>
                                        <input type="checkbox" name="shower" id="shower" value={3} onChange={handleFacilityChange} />
                                        <label className="component-17-parent" htmlFor="shower">
                                            <img className="component-17-icon" src="./assets/img/search/shower.svg" alt="" />
                                            <img className="component-17-icon active" src="./assets/img/download.png" alt="" />

                                            <div className="reset-wrapper">
                                                <div className="shower">Shower</div>
                                            </div>
                                        </label>
                                        <input type="checkbox" name="needKeyAccess" id="Key_access" checked={checkboxes.needKeyAccess}
                                            onChange={handleChange} />

                                        <label className="component-19-parent" htmlFor="Key_access">
                                            <img className="component-17-icon" src="./assets/img/search/Component 19.svg" alt="" />
                                            <img className="component-17-icon active" src="./assets/img/Property 1=Frame 182 (8).svg" alt="" />

                                            <div className="reset-wrapper">
                                                <div className="shower" style={{ width: "120px" }}>Key access</div>
                                            </div>
                                        </label>
                                        <input type="checkbox" name="Bidet" id="Bidet" value={2} onChange={handleFacilityChange} />

                                        <label className="component-18-parent" htmlFor="Bidet">
                                            <img className="component-17-icon" src="./assets/img/search/Component 18.svg" alt="" />
                                            <img className="component-17-icon active" src="./assets/img/Property 1=Frame 182 (7).svg" alt="" />
                                            <div className="reset-wrapper">
                                                <div className="shower">Bidet</div>
                                            </div>
                                        </label>
                                        <input type="checkbox" name="isFree" id="Free" checked={checkboxes.isFree}
                                            onChange={handleChange} />

                                        <label className="component-21-parent" htmlFor="Free">
                                            <img className="component-17-icon" src="./assets/img/search/Component 21.svg" alt="" />
                                            <img className="component-17-icon active" src="./assets/img/Property 1=Frame 182 (9).svg" alt="" />
                                            <div className="reset-wrapper">
                                                <div className="shower">Free</div>
                                            </div>
                                        </label>
                                        <abbr className="tooltippppy"
                                        >
                                            <img className="vector-icon4 custom-tooltip" src="./assets/img/search/Vector3.svg" alt="" />
                                            <span class="tooltiptext">This restroom may need an exclusive access or membership to enter"</span>

                                        </abbr>
                                    </div>
                                </div>
                            </div>
                            <div className="main">

                                <div className="item-div" id="box">
                                    <img className="caretcircleright-icon" alt="" src="./assets/img/CaretCircleRight.svg" onClick={() => document.getElementById('box').scrollBy({ left: 200, behavior: 'smooth' })} />
                                    <div className="title10 ">
                                        <input type="checkbox" name="category" value="1" id="gas" onChange={handleCategoryChange} />
                                        <label htmlFor="gas">
                                            <img src="./assets/img/search/Vector (2).svg" alt="" />

                                            <h5>Gas Stations</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="6" id="Restaurants" onChange={handleCategoryChange} />
                                        <label htmlFor="Restaurants">
                                            <img src="./assets/img/search/Vector (3).svg" alt="" />

                                            <h5>Restaurants & Cafés</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="0" id="Public" onChange={handleCategoryChange} />
                                        <label htmlFor="Public">
                                            <img src="./assets/img/search/Vector (5).svg" alt="" />

                                            <h5>Public Buildings</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="5" id="Restrooms" onChange={handleCategoryChange} />
                                        <label htmlFor="Restrooms">
                                            <img src="./assets/img/search/Vector (6).svg" alt="" />

                                            <h5>Public Restrooms</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="3" id="Grocery" onChange={handleCategoryChange} />
                                        <label htmlFor="Grocery">
                                            <img src="./assets/img/search/Vector (7).svg" alt="" />

                                            <h5>Grocery Stores</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="2" id="Park" onChange={handleCategoryChange} />
                                        <label htmlFor="Park">
                                            <img src="./assets/img/search/Vector (8).svg" alt="" />

                                            <h5>Park & Beach</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="Bars" value="12" onChange={handleCategoryChange} />
                                        <label htmlFor="Bars">
                                            <img src="./assets/img/search/Vector (9).svg" alt="" />

                                            <h5>Bars</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="Malls" value="7" onChange={handleCategoryChange} />
                                        <label htmlFor="Malls">
                                            <img src="./assets/img/search/Vector (10).svg" alt="" />

                                            <h5>Malls</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="Gym" value="10" onChange={handleCategoryChange} />
                                        <label htmlFor="Gym">
                                            <img src="./assets/img/search/Vector (3).png" alt="" />

                                            <h5>Gym</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" value="8" name="category" id="BusTerminals" onChange={handleCategoryChange} />
                                        <label htmlFor="BusTerminals">
                                            <img src="./assets/img/search/Vector (11).svg" alt="" />

                                            <h5>Bus Terminals</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" value="9" id="TrainStations" onChange={handleCategoryChange} />
                                        <label htmlFor="TrainStations">
                                            <img src="./assets/img/search/Vector (15).svg" alt="" />

                                            <h5>Train Stations</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="Airports" value="11" onChange={handleCategoryChange} />
                                        <label htmlFor="Airports">
                                            <img src="./assets/img/search/Vector (12).svg" alt="" />

                                            <h5>Airports</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="SubwayStations" value="13" onChange={handleCategoryChange} />
                                        <label htmlFor="SubwayStations">
                                            <img src="./assets/img/search/Vector (13).svg" alt="" />

                                            <h5>Subway Stations</h5>
                                        </label>
                                    </div>
                                    <div className="title10">
                                        <input type="checkbox" name="category" id="Other" value="4" onChange={handleCategoryChange} />
                                        <label htmlFor="Other">
                                            <img src="./assets/img/search/Vector (14).svg" alt="" />

                                            <h5>Other</h5>
                                        </label>
                                    </div>
                                    <img className="caretcircleleft-icon" alt="" src="./assets/img/CaretCircleLeft.svg" onClick={() => document.getElementById('box').scrollBy({ left: -200, behavior: 'smooth' })}
                                    />
                                </div>

                            </div>
                        </div>
                        : <div className="searchy">

                            <div className={`searchbar mobb`}>
                                <div className="frame-parent">
                                    <div className="frame-group">
                                        <div className="frame-container">
                                            <div className="frame-wrapper">
                                                <div className="frame-div">
                                                    <input placeholder="Search..."
                                                        value={searchTerm}
                                                        onChange={handleSearch}
                                                        name="searchTerm"
                                                        className="search-for-restroom-wrapper" />

                                                </div>
                                                {windowidth < 700 && filteredRestrooms.length ?
                                                    <></> :
                                                    <>
                                                        <input type="checkbox" name="nearby" id="nearby" checked={checkboxes.nearby}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="gislocation-poi-o-parent" htmlFor="nearby">
                                                            <img className="gislocation-poi-o-icon" alt="" src="./assets/img/gis_location-poi-o.svg" />
                                                            <img className="gislocation-poi-o-icon checked" alt="" src="./assets/img/gis_location-poi-o-checked.svg" />

                                                            <div className="search-for-restroom">Nearby</div>
                                                        </label>
                                                    </>
                                                }
                                                <button className="filters-parent" type="button" id="frameContainer" onClick={() => { handleFilterChange(); }} >
                                                    <div className="search-for-restroom">
                                                        <div className="mob search">
                                                            <IoIosSearch />

                                                        </div>
                                                    </div>
                                                </button>
                                                <button className="filters-parent" type="button" id="frameContainer" onClick={hideshow}>
                                                    <div className="search-for-restroom">
                                                        <div className="mob">
                                                            <img src="./assets/img/sliders-horizontal.svg" />
                                                        </div>
                                                    </div>
                                                </button>



                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {windowidth < 700 && filteredRestrooms?.length ?
                                    <></> :

                                    <div className="main">

                                        <div className="item-div" id="box">
                                            <div className="title10 ">
                                                <input type="checkbox" name="category" value="1" id="gas" onChange={handleCategoryChange} />
                                                <label htmlFor="gas">
                                                    <img src="./assets/img/add/Vector (4).svg" alt="" />

                                                    <h5>Gas Stations</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="6" id="Restaurants" onChange={handleCategoryChange} />
                                                <label htmlFor="Restaurants">
                                                    <img src="./assets/img/add/Vector (5).svg" alt="" />

                                                    <h5>Restaurants & Cafés</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="0" id="Public" onChange={handleCategoryChange} />
                                                <label htmlFor="Public">
                                                    <img src="./assets/img/add/Vector (8).svg" alt="" />

                                                    <h5>Public Buildings</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="5" id="Restrooms" onChange={handleCategoryChange} />
                                                <label htmlFor="Restrooms">
                                                    <img src="./assets/img/add/Vector (9).svg" alt="" />

                                                    <h5>Public Restrooms</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="3" id="Grocery" onChange={handleCategoryChange} />
                                                <label htmlFor="Grocery">
                                                    <img src="./assets/img/add/Vector (10).svg" alt="" />

                                                    <h5>Grocery Stores</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="2" id="Park" onChange={handleCategoryChange} />
                                                <label htmlFor="Park">
                                                    <img src="./assets/img/add/Vector (11).svg" alt="" />

                                                    <h5>Park & Beach</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="Bars" value="12" onChange={handleCategoryChange} />
                                                <label htmlFor="Bars">
                                                    <img src="./assets/img/add/Vector (12).svg" alt="" />

                                                    <h5>Bars</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="Malls" value="7" onChange={handleCategoryChange} />
                                                <label htmlFor="Malls">
                                                    <img src="./assets/img/add/Vector (13).svg" alt="" />

                                                    <h5>Malls</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="Gym" value="10" onChange={handleCategoryChange} />
                                                <label htmlFor="Gym">
                                                    <img src="./assets/img/add/Vector (20).svg" alt="" />

                                                    <h5>Gym</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" value="8" name="category" id="BusTerminals" onChange={handleCategoryChange} />
                                                <label htmlFor="BusTerminals">
                                                    <img src="./assets/img/add/Vector (15).svg" alt="" />

                                                    <h5>Bus Terminals</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" value="9" id="TrainStations" onChange={handleCategoryChange} />
                                                <label htmlFor="TrainStations">
                                                    <img src="./assets/img/add/Vector (16).svg" alt="" />

                                                    <h5>Train Stations</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="Airports" value="11" onChange={handleCategoryChange} />
                                                <label htmlFor="Airports">
                                                    <img src="./assets/img/add/Vector (17).svg" alt="" />

                                                    <h5>Airports</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="SubwayStations" value="13" onChange={handleCategoryChange} />
                                                <label htmlFor="SubwayStations">
                                                    <img src="./assets/img/add/Vector (18).svg" alt="" />

                                                    <h5>Subway Stations</h5>
                                                </label>
                                            </div>
                                            <div className="title10">
                                                <input type="checkbox" name="category" id="Other" value="4" onChange={handleCategoryChange} />
                                                <label htmlFor="Other">
                                                    <img src="./assets/img/add/Vector (19).svg" alt="" />

                                                    <h5>Other</h5>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                }
                                <div className="filter" style={{ display: showFilter ? "block" : "none" }}>
                                    <b className="filters">Filters</b>
                                    <div className="quick-shortcuts">Quick shortcuts</div>
                                    <div className="gender">Gender</div>
                                    <div className="restroom-type">Restroom Type</div>
                                    <div className="categories">Categories</div>

                                    <label className="component-69">
                                        <img className="vector-icon2" alt="" src="./assets/img/searchmob/Vector (22).svg" />
                                        <div className="open-now">Baby changing</div>
                                    </label>
                                    <input type="checkbox" name="isOpen" id="Open" checked={checkboxes.isOpen}
                                        onChange={handleChange}
                                    />

                                    <label className="component-67" htmlFor="Open">
                                        <img className="vector-icon" alt="" src="./assets/img/searchmob/Vector (20).svg" />
                                        {/* <img className="vector-icon active" alt="" src="./assets/img/Property 1=Frame 182 (5).svg" /> */}

                                        <div className="open-now">Open now</div>
                                    </label>
                                    <input type="checkbox" name="chair" id="chair" value={4} onChange={handleFacilityChange} />
                                    <label className="component-68" htmlFor="chair">
                                        <img className="vector-icon1" alt="" src="./assets/img/searchmob/Vector (21).svg" />
                                        {/* <img className="vector-icon1 active" alt="" src="./assets/img/Property 1=Frame 182 (4).svg" /> */}

                                        <div className="open-now">Wheelchair</div>
                                    </label>
                                    <input type="checkbox" name="Baby" id="Baby" value={1} onChange={handleFacilityChange} />
                                    <label htmlFor="Baby" className="component-69">
                                        <img className="vector-icon2" alt="" src="./assets/img/searchmob/baby-change.svg" />
                                        {/* <img className="vector-icon2 active" alt="" src="./assets/img/Property 1=Frame 182 (6).svg" /> */}

                                        <div className="open-now">Baby changing</div>
                                    </label>


                                    <button className="vector-wrapper" type="button" onClick={hideshow}>
                                        <img className="vector-icon3" alt="" src="./assets/img/searchmob/Vector (46).svg" />
                                    </button>

                                    <input type="checkbox" name="isFree" id="Free" checked={checkboxes.isFree}
                                        onChange={handleChange} />
                                    <label className="component-71" htmlFor="Free">
                                        <img className="vector-icon4" alt="" src="./assets/img/searchmob/Vector (24).svg" />
                                        {/* <img className="vector-icon4 active" src="./assets/img/Property 1=Frame 182 (9).svg" alt="" /> */}

                                        <div className="open-now">Free</div>
                                    </label>
                                    <input type="checkbox" name="shower" id="shower" value={3} onChange={handleFacilityChange} />

                                    <label className="component-74" htmlFor="shower">
                                        <img className="group-icon" alt="" src="./assets/img/searchmob/Group (6).svg" />
                                        {/* <img className="group-icon active" src="./assets/img/download.png" alt="" /> */}

                                        <div className="open-now">Shower</div>
                                    </label>
                                    <input type="checkbox" name="Bidet" id="Bidet" value={2} onChange={handleFacilityChange} />

                                    <label className="component-77" htmlFor="Bidet">
                                        <img className="vector-icon2" alt="" src="./assets/img/searchmob/Vector (23).svg" />
                                        {/* <img className="vector-icon2 active" src="./assets/img/Property 1=Frame 182 (7).svg" alt="" /> */}

                                        <div className="open-now">Bidet</div>
                                    </label>

                                    <input type="checkbox" name="needKeyAccess" id="Key_access" checked={checkboxes.needKeyAccess}
                                        onChange={handleChange} />
                                    <label className="component-79" htmlFor="Key_access" >
                                        <img className="vector-icon2" alt="" src="./assets/img/searchmob/Vector (22).svg" />
                                        {/* <img className="vector-icon2 active" src="./assets/img/Property 1=Frame 182 (8).svg" alt="" /> */}

                                        <div className="open-now">Key access</div>
                                    </label>

                                    <input type="radio" name="gender" id="allgender" />
                                    <label className="component-86" htmlFor="allgender" onClick={() => setSelectedValue('ALL GENDER')} >
                                        <img className="vector-icon7" alt="" src="./assets/img/searchmob/Vector (26).svg" />
                                        <div className="open-now">All Gender</div>
                                    </label>
                                    <input type="radio" name="gender" id="WOMEN" />

                                    <label className="component-92" htmlFor="WOMEN" onClick={() => setSelectedValue('WOMEN')}>
                                        <img className="vector-icon8" alt="" src="./assets/img/searchmob/Vector (27).svg" />
                                        <div className="open-now">Man</div>
                                    </label>
                                    <input type="radio" name="gender" id="MAN" />

                                    <label className="component-90" htmlFor="MAN" onClick={() => setSelectedValue('MAN')}>
                                        <img className="vector-icon7" alt="" src="./assets/img/searchmob/Vector (28).svg" />
                                        <div className="open-now">Women</div>
                                    </label>


                                    <input type="radio" name="type" id="SINGLE" />

                                    <label className="component-94" htmlFor="SINGLE" onClick={() => setSelectedValue1('SINGLE')}>
                                        <img className="vector-icon1" alt="" src="./assets/img/searchmob/Vector (29).svg" />
                                        <div className="open-now">Single</div>
                                    </label>
                                    <input type="radio" name="type" id="SHARED" />

                                    <label className="component-97" htmlFor="SHARED" onClick={() => setSelectedValue1('SHARED')}>
                                        <img className="vector-icon11" alt="" src="./assets/img/searchmob/Vector (30).svg" />
                                        <div className="open-now">Shared</div>
                                    </label>


                                    <input type="checkbox" name="category" value="1" id="gass" onChange={handleCategoryChange} />

                                    <label htmlFor="gass" className="component-125">
                                        <img className="vector-icon12" alt="" src="./assets/img/searchmob/Vector (31).svg" />
                                        <div className="open-now">Gas Stations</div>
                                    </label>
                                    <input type="checkbox" name="category" value="6" id="Restaurantss" onChange={handleCategoryChange} />

                                    <label className="component-126" htmlFor="Restaurantss">
                                        <img className="vector-icon13" alt="" src="./assets/img/searchmob/Vector (32).svg" />
                                        <div className="open-now">Restaurants & Cafés</div>
                                    </label>
                                    <input type="checkbox" name="category" value="0" id="Publicc" onChange={handleCategoryChange} />

                                    <label className="component-127" htmlFor="Publicc">
                                        <img className="vector-icon12" alt="" src="./assets/img/searchmob/Vector (33).svg" />
                                        <div className="open-now">Public Buildings</div>
                                    </label>
                                    <input type="checkbox" name="category" value="5" id="Restroomss" onChange={handleCategoryChange} />

                                    <label className="component-128" htmlFor="Restroomss">
                                        <img className="vector-icon15" alt="" src="./assets/img/searchmob/Vector (34).svg" />
                                        <div className="open-now">Public Restroom</div>
                                    </label>
                                    <input type="checkbox" name="category" value="3" id="Groceryy" onChange={handleCategoryChange} />

                                    <label className="component-129" htmlFor="Groceryy">
                                        <img className="vector-icon16" alt="" src="./assets/img/searchmob/Vector (35).svg" />
                                        <div className="open-now">Grocery Stores</div>
                                    </label>
                                    <input type="checkbox" name="category" value="2" id="Parkk" onChange={handleCategoryChange} />

                                    <label className="component-130" htmlFor="Parkk">
                                        <img className="vector-icon17" alt="" src="./assets/img/searchmob/Vector (36).svg" />
                                        <div className="open-now">Park & Beach</div>
                                    </label>
                                    <input type="checkbox" name="category" id="Barss" value="12" onChange={handleCategoryChange} />

                                    <label className="component-131" htmlFor="Barss">
                                        <img className="vector-icon13" alt="" src="./assets/img/searchmob/Vector (37).svg" />
                                        <div className="open-now">Bars</div>
                                    </label>
                                    <input type="checkbox" name="category" id="Mallss" value="7" onChange={handleCategoryChange} />

                                    <label className="component-132" htmlFor="Mallss">
                                        <img className="vector-icon16" alt="" src="./assets/img/searchmob/Vector (38).svg" />
                                        <div className="open-now">Malls</div>
                                    </label>
                                    <input type="checkbox" name="category" id="Gymm" value="10" onChange={handleCategoryChange} />

                                    <label className="component-133" htmlFor="Gymm">
                                        <img className="vector-icon20" alt="" src="./assets/img/searchmob/Vector (39).svg" />
                                        <div className="open-now">Gym</div>
                                    </label>
                                    <input type="checkbox" value="8" name="category" id="BusTerminalss" onChange={handleCategoryChange} />

                                    <label className="component-134" htmlFor="BusTerminalss">
                                        <img className="vector-icon21" alt="" src="./assets/img/searchmob/Vector (40).svg" />
                                        <div className="open-now">Bus Terminals</div>
                                    </label>
                                    <input type="checkbox" name="category" value="9" id="TrainStationss" onChange={handleCategoryChange} />

                                    <label className="component-135" htmlFor="TrainStationss">
                                        <img className="vector-icon22" alt="" src="./assets/img/searchmob/Vector (41).svg" />
                                        <div className="open-now">Train Stations</div>
                                    </label>
                                    <input type="checkbox" name="category" id="Airportss" value="11" onChange={handleCategoryChange} />

                                    <label className="component-136" htmlFor="Airportss">
                                        <img className="vector-icon23" alt="" src="./assets/img/searchmob/Vector (42).svg" />
                                        <div className="open-now">Airports</div>
                                    </label>
                                    <input type="checkbox" name="category" id="SubwayStationss" value="13" onChange={handleCategoryChange} />

                                    <label className="component-137" htmlFor="SubwayStationss">
                                        <img className="vector-icon24" alt="" src="./assets/img/searchmob/Vector (43).svg" />
                                        <div className="open-now">Subway Stations</div>
                                    </label>
                                    <input type="checkbox" name="category" id="Otherr" value="4" onChange={handleCategoryChange} />

                                    <label className="component-138" htmlFor="Otherr">
                                        <img className="vector-icon25" alt="" src="./assets/img/searchmob/Vector (44).svg" />
                                        <div className="open-now">Other</div>
                                    </label>
                                    <button onClick={() => { handleFilterChange(); hideshow() }} id="frameContainer1" className="search apply-wrapper" >
                                        <div className="apply">Apply</div>
                                    </button>
                                    <button type="reset" className="vector-parent" onClick={handleReset}>
                                        <img className="vector-icon26" alt="" src="./assets/img/Vector (47).svg" />
                                        <div className="open-now">Reset</div>
                                    </button>
                                    <div className="filter-child">
                                    </div>
                                    <div className="filter-item">
                                    </div>
                                    <div className="filter-inner">
                                    </div>
                                </div>

                            </div>


                        </div>
                    }
                    <div >
                        {!selectedRestroom ?
                            filteredRestrooms ?
                                filteredRestrooms?.length <= 0 ?

                                    <Container fluid className={`changecontainer`} >

                                        <Row>
                                            <Col lg={7} md={12} sm={12}>
                                                <Row>

                                                    <div className="empty">
                                                        <img src="./assets/img/gif.gif" alt="" />
                                                        <h3>Sorry,
                                                            <br />
                                                            there are no restrooms in this area</h3>
                                                        <a className="nav-link addRest" onClick={() => { window.location.href = "/AddNew" }}>
                                                            <img src="./assets/Plus.svg" alt="" />Add
                                                            Restroom
                                                        </a>
                                                        <p>
                                                            Help people reach the nearest restroom to them by adding a restroom now!
                                                        </p>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col md={12} lg={5} sm={12} style={{ border: "2px solid black", height: "558px" }}>
                                            </Col>
                                        </Row>
                                    </Container>
                                    :
                                    <Container fluid className={`changecontainer ${activeButton === 'map' ? 'mape' : null}`} >

                                        <Row>

                                            <Col lg={7} md={12} sm={12}>
                                                <Row className={` ${isActivee ? 'active' : ''}`}>
                                                    {windowidth > 1024 ?
                                                        <p style={{ paddingLeft: "5px" }}>Result: {totalResults} Places</p>
                                                        : null}
                                                    <div className="divline" onClick={toggleClass}></div>
                                                    <div className="mobonly">
                                                        <input type="checkbox" name="nearby" id="nearby" checked={checkboxes.nearby}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="gislocation-poi-o-parent" htmlFor="nearby">
                                                            <img className="gislocation-poi-o-icon" alt="" src="./assets/img/location_on.svg" />
                                                            <img className="gislocation-poi-o-icon checked" alt="" src="./assets/img/gis_location-poi-o-checked.svg" />

                                                            <div className="nearby">Nearby</div>
                                                        </label>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="result-9">Result : {totalResults} places</div>

                                                            <div className="d-flex justify-content-end">
                                                                <button className={`component-143 ${activeButton === 'cards' ? 'iconnnn' : ''}`} onClick={() => handleButtonClick('cards')} id="component143Container" >
                                                                    <img className="view-agenda-icon" alt="" src="./assets/view_agenda.svg" />
                                                                    <div className="cards">Cards</div>
                                                                </button>
                                                                <button className={`component-143 ${activeButton === 'map' ? 'iconnnn' : ''}`} onClick={() => handleButtonClick('map')} id="iconContainer">

                                                                    <img className="sliders-horizontal-icon" alt="" src="./assets/location_on (1).svg" />
                                                                    <div className="background">
                                                                        Map
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {windowidth > 1024 ?
                                                        filteredRestrooms
                                                            .map((filterm) => (
                                                                <Col md="6" lg="4" sm="12" className="cardrestroom" key={filterm.id}>
                                                                    <div className="rectangle-parent">
                                                                        <button className="component-14" onClick={() => handleAddToFavorite(filterm.id)}>
                                                                            {isFavorited[filterm.id] ? (
                                                                                <img src="./assets/img/star-fill.svg" alt=" Favorited" />

                                                                            ) : (
                                                                                <img src="./assets/img/star-empty.svg" alt="Not Favorited" />

                                                                            )}

                                                                        </button>
                                                                        {/* {filterm.photos.length > 0 && ( */}
                                                                        <Swiper
                                                                            spaceBetween={10}
                                                                            slidesPerView={1}
                                                                            pagination={{ clickable: true }}
                                                                            autoplay={{ delay: 3000 }}
                                                                            className="photo-swiper"
                                                                            navigation={true}
                                                                            modules={[FreeMode, Navigation, Pagination]}

                                                                        >
                                                                            {filterm.photos?.length == 0 ?
                                                                                <SwiperSlide>
                                                                                    <img src="assets/img/noimg.png" alt="Restroom" className="restroom-photo" />
                                                                                </SwiperSlide>
                                                                                :
                                                                                filterm.photos?.map(photo => (
                                                                                    <SwiperSlide key={photo.id}>
                                                                                        <img src={photo.url} alt="Restroom" className="restroom-photo" />
                                                                                    </SwiperSlide>
                                                                                ))}
                                                                        </Swiper>
                                                                        {/* )} */}
                                                                        <div className="frame-parent">
                                                                            {/* <div className="d-flex justify-content-between flex-wrap w-100">
                                                                    <div className="vector-parent">
                                                                        <img className="vector-icon" alt="" src="./assets/img/arrow.svg" />
                                                                    </div>
                                                                    <div className="vector-group">
                                                                        <img className="vector-icon6" alt="" src="./assets/img/car.svg" />
                                                                        <div className="div">53 min</div>
                                                                    </div>
                                                                </div> */}
                                                                            <div className="frame-group d-flex justify-content-between flex-wrap">
                                                                                <div className="manx-museum-wrapper">
                                                                                    <div
                                                                                        className={`manx-museum ${filterm.name.length > 15 ? 'hoverexceed' : ''} `}
                                                                                        title={filterm.name.length > 15 ? filterm.name : ''} data-fullname={filterm.name.length > 15 ? filterm.name : ''}
                                                                                    >
                                                                                        {filterm.name.length > 15 ? `${filterm.name.slice(0, 15)}...` : filterm.name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="frame-wrapper ml-auto">
                                                                                    <div className="parent">
                                                                                        {/* <div className="div">5</div> */}

                                                                                        <div className="ratings" id="ratingsText">
                                                                                            {Math.ceil(filterm.avgRating) > 0 ? (
                                                                                                <div className="star-wrapper">
                                                                                                    <div className="star">
                                                                                                        {[...Array(Math.ceil(filterm.avgRating))].map((_, index) => (
                                                                                                            <img
                                                                                                                key={index}
                                                                                                                className="vector-icon1"
                                                                                                                alt=""
                                                                                                                src="./assets/img/search/star.svg"
                                                                                                            />
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}
                                                                                            <span className="ratings-txt-container">
                                                                                                {/* <span> </span> */}
                                                                                                <span className="ratings1">({filterm.avgRating}) ratings</span>
                                                                                                {/* <span> </span> */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100">
                                                                                <div className="frame-container">
                                                                                    <div className="manhattan-wrapper">
                                                                                        <div className="miles-from-your">{filterm.address}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="directions-parent d-flex align-items-center">
                                                                                    <div className="arrow-top-right-wrapper">
                                                                                        <img className="arrow-top-right-icon" alt="" src="./assets/img/direction.svg" />
                                                                                    </div>
                                                                                    <div className="directions">Directions</div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="frame-div justify-content-between" >
                                                                                <div className="closed-parent">
                                                                                    <>
                                                                                        {filterm.openHours?.some(hour =>
                                                                                            hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                                        ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div"> Closed</div>}
                                                                                    </>
                                                                                    {/* <div className="frame-item"></div>
                                                                    <div className="opens-at-0930">Opens at 09:30</div> */}
                                                                                </div>
                                                                                <div className="more-details-wrapper" id="frameContainer1">
                                                                                    <div className="more-details" onClick={() => handleMoreDetailsClick(filterm)}>More details</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        {filterm.isFree == 'FALSE' ?
                                                                            <div className="rectangle-group">
                                                                                <div className="group-child"></div>
                                                                                <div className="paid">Paid</div>
                                                                                <div className="vector-wrapper">
                                                                                    <img className="vector-icon7" alt="" src="./assets/img/dolla4r.svg" />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </Col>
                                                            ))
                                                        :
                                                        filteredRestrooms
                                                            .map((filterm) => (
                                                                <Col md="6" lg="4" sm="12" className="cardrestroom" key={filterm.id}>
                                                                    <div className="rectangle-parent" onClick={() => handleMoreDetailsClick(filterm)}>
                                                                        <button className="component-14" onClick={() => handleAddToFavorite(filterm.id)}>
                                                                            {isFavorited[filterm.id] ? (
                                                                                <img src="./assets/img/star-fill.svg" alt="Not Favorited" />

                                                                            ) : (
                                                                                <img src="./assets/img/star-empty.svg" alt="Favorited" />

                                                                            )}

                                                                        </button>
                                                                        {/* {filterm.photos.length > 0 && ( */}
                                                                        <Swiper
                                                                            spaceBetween={10}
                                                                            slidesPerView={1}
                                                                            pagination={{ clickable: true }}
                                                                            autoplay={{ delay: 3000 }}
                                                                            className="photo-swiper"
                                                                            navigation={true}
                                                                            modules={[FreeMode, Navigation, Pagination]}

                                                                        >
                                                                            {filterm.photos?.length == 0 ?
                                                                                <SwiperSlide>
                                                                                    <img src="assets/img/noimg.png" alt="Restroom" className="restroom-photo" />
                                                                                </SwiperSlide>
                                                                                :
                                                                                filterm.photos.map(photo => (
                                                                                    <SwiperSlide key={photo.id}>
                                                                                        <img src={photo.url} alt="Restroom" className="restroom-photo" />
                                                                                    </SwiperSlide>
                                                                                ))}
                                                                        </Swiper>
                                                                        {/* )} */}
                                                                        <div className="frame-parent">

                                                                            <div className="frame-group d-flex justify-content-between flex-wrap">
                                                                                <div className="manx-museum-wrapper">
                                                                                    <div
                                                                                        className={`manx-museum ${filterm.name.length > 15 ? 'hoverexceed' : ''} `}
                                                                                        title={filterm.name.length > 15 ? filterm.name : ''} data-fullname={filterm.name.length > 15 ? filterm.name : ''}
                                                                                    >
                                                                                        {filterm.name.length > 15 ? `${filterm.name.slice(0, 15)}...` : filterm.name}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap">
                                                                                <div className="frame-container">
                                                                                    <div className="manhattan-wrapper">
                                                                                        <div className="miles-from-your">{filterm.address}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="frame-wrapper ml-auto">
                                                                                    <div className="parent">
                                                                                        {/* <div className="div">5</div> */}

                                                                                        <div className="ratings" id="ratingsText">
                                                                                            {Math.ceil(filterm.avgRating) > 0 ? (
                                                                                                <div className="star-wrapper">
                                                                                                    <div className="star">
                                                                                                        {[...Array(Math.ceil(filterm.avgRating))].map((_, index) => (
                                                                                                            <img
                                                                                                                key={index}
                                                                                                                className="vector-icon1"
                                                                                                                alt=""
                                                                                                                src="./assets/img/search/star.svg"
                                                                                                            />
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}
                                                                                            <span className="ratings-txt-container">
                                                                                                {/* <span> </span> */}

                                                                                                (<span className="ratings1">{filterm.avgRating} ratings</span>)
                                                                                                {/* <span> </span> */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="frame-div justify-content-between" >
                                                                                <div className="closed-parent">
                                                                                    <>
                                                                                        {filterm.openHours?.some(hour =>
                                                                                            hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                                        ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div"> Closed</div>}
                                                                                    </>
                                                                                    {/* <div className="frame-item"></div>
                                                                                              <div className="opens-at-0930">Opens at 09:30</div> */}
                                                                                </div>
                                                                                <button className=" mobdirectionbtn" onClick={() => handleButtonClick('map')}>
                                                                                    <div className="">
                                                                                        <img className="" alt="" src="./assets/img/arrow_top_right (1).svg" />
                                                                                    </div>
                                                                                    <div className="">Directions</div>

                                                                                </button>
                                                                                {/* <div className="more-details-wrapper" id="frameContainer1">
                                                                                            <div className="more-details">More details</div>
                                                                                        </div> */}
                                                                            </div>


                                                                        </div>
                                                                        {filterm.isFree == 'FALSE' ?
                                                                            <div className="rectangle-group">
                                                                                <div className="group-child"></div>
                                                                                <div className="paid">Paid</div>
                                                                                <div className="vector-wrapper">
                                                                                    <img className="vector-icon7" alt="" src="./assets/img/dolla4r.svg" />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </Col>
                                                            ))
                                                    }

                                                    {activeButton != 'map' ?
                                                        <div className="pagination-buttons">
                                                            <button
                                                                onClick={() => handleFilterChange(currentPage - 1)}
                                                                disabled={currentPage === 1}
                                                            >
                                                                Prev<GrFormPreviousLink />
                                                            </button>
                                                            <button
                                                                onClick={() => handleFilterChange(currentPage + 1)}
                                                                disabled={!hasNextPage}
                                                            >
                                                                <GrFormNextLink />Next

                                                            </button>
                                                        </div>
                                                        : null}
                                                </Row>


                                            </Col>
                                            <Col md={12} lg={5} sm={12} style={{ border: "2px solid black" }}>

                                            </Col>
                                        </Row>
                                    </Container>
                                : null
                            : null}
                        {windowidth > 1024 ?
                            selectedRestroom && (
                                <Container fluid className="roomSelected">
                                    <Row>
                                        <Col lg={7} md={12} >
                                            <div>
                                                <div
                                                    className={`tabdiv ${isActivee ? 'active' : ''} room-details-container`}
                                                    ref={tabDivRef}

                                                >



                                                    <div className="frame-parent">
                                                        <div className="frame-wrapper">
                                                            <div className="frame-container">
                                                                <div className="rectangle-parent">

                                                                    {selectedRestroom.photos?.length == 0 ?
                                                                        <div className="d-flex align-items-center">
                                                                            <img className="frame-child" alt="" src="assets/img/noimg.png" />
                                                                            <button className="addBtnPhoto" onClick={openModal}>

                                                                                <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                <div className="add-photos">Add photos</div>
                                                                            </button>
                                                                        </div>
                                                                        : selectedRestroom.photos?.length == 1 ? (
                                                                            <div className="d-flex align-items-center">
                                                                                <img className="frame-child" alt="" src={selectedRestroom.photos[0].url} />
                                                                                <button className="addBtnPhoto" onClick={openModal}>

                                                                                    <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                    <div className="add-photos">Add photos</div>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="swipersliders">
                                                                                    <Swiper
                                                                                        style={{
                                                                                            '--swiper-navigation-color': '#D9D9D9',
                                                                                            '--swiper-pagination-color': '#D9D9D9',
                                                                                        }}
                                                                                        loop={true}
                                                                                        spaceBetween={10}
                                                                                        freeMode={true}
                                                                                        pagination={{ clickable: true }}
                                                                                        thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                                                                                        modules={[FreeMode, Pagination, Thumbs]}
                                                                                        className="mySwiper2 first-swiper"
                                                                                    >

                                                                                        {selectedRestroom?.photos?.map((photo, index) => (
                                                                                            <SwiperSlide>
                                                                                                <img src={selectedRestroom.photos[index].url} alt="" />
                                                                                            </SwiperSlide>
                                                                                        ))}
                                                                                    </Swiper>
                                                                                    <>
                                                                                        <Swiper
                                                                                            onSwiper={handleSwiper}
                                                                                            freeMode={true}

                                                                                            loop={true}
                                                                                            spaceBetween={0}
                                                                                            slidesPerView={4}
                                                                                            navigation={true}
                                                                                            thumbs={true}
                                                                                            watchSlidesProgress={true}
                                                                                            modules={[FreeMode, Navigation, Thumbs]}
                                                                                            className="mySwiper second-swiper"
                                                                                            direction="vertical"

                                                                                        >
                                                                                            {selectedRestroom.photos.map((photo, index) => (
                                                                                                <SwiperSlide>
                                                                                                    <img src={selectedRestroom.photos[index].url} alt="" />
                                                                                                </SwiperSlide>
                                                                                            ))}
                                                                                        </Swiper>

                                                                                    </>
                                                                                </div>
                                                                                <div className="addbtndiv">
                                                                                    <button className="addBtnPhoto " onClick={openModal}>

                                                                                        <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                        <div className="add-photos">Add photos</div>
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    <div className="stepney-city-farm">{selectedRestroom.name}</div>

                                                                    <div className="frame-div">
                                                                        <div className="frame-parent1">
                                                                            <div className="wrapper">
                                                                                <div className="wheelchair">{selectedRestroom.avgRating.toFixed(1)}</div>
                                                                            </div>
                                                                            <div className="star">
                                                                            </div>
                                                                            <div className="vector-group">
                                                                                {Math.ceil(selectedRestroom.avgRating) > 0 ?
                                                                                    <>
                                                                                        {[...Array(Math.ceil(selectedRestroom.avgRating))].map((_, index) => (
                                                                                            <div className="star1">
                                                                                                <img className="vector-icon1" alt="" src="./assets/img/search/star.svg" />
                                                                                            </div>
                                                                                        ))}
                                                                                    </> : null}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="sports-complex">{selectedRestroom.address}</div>
                                                                    <div className="frame-parent2">
                                                                        <div className="overview-wrapper">
                                                                            <div className="wheelchair">Overview </div>
                                                                        </div>
                                                                        <div className="reviews-wrapper" id="frameContainer1">
                                                                            <div className="wheelchair">Reviews</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="line-div">
                                                                    </div>
                                                                    <div className="d-flex mr-5 justify-content-center">
                                                                        <div className="component-14-parent marrr">

                                                                            <button className="component-14" onClick={() => handleAddToFavorite(selectedRestroom.id)}>
                                                                                {isFavorited[selectedRestroom.id] ? (
                                                                                    <img src="./assets/img/Frame (1).svg" alt="Not Favorited" className="w-100" />

                                                                                ) : (
                                                                                    <img src="./assets/img/Frame.svg" alt="Favorited" className="w-100" />

                                                                                )}

                                                                            </button>
                                                                            <div className="wheelchair">Favorite</div>
                                                                        </div>
                                                                        <button className="frame-parent3" onClick={handleGetDirections} >
                                                                            <div className="group-wrapper">
                                                                                <div className="arrow-top-right-wrapper">
                                                                                    <div className="arrow-top-right">
                                                                                        <img className="arrow-top-right-icon" alt="" src="./assets/img/arrow_top_right.svg" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wheelchair">Directions</div>
                                                                        </button>
                                                                        <button className="frame-parent3 editbtn" onClick={() => { window.location.href = `/edit-room/${selectedRestroom.id}` }} >
                                                                            <div className="group-wrapper">
                                                                                <div className="arrow-top-right-wrapper">
                                                                                    <div className="arrow-top-right">
                                                                                        <MdEdit className="arrow-top-right-icon" />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wheelchair">Update</div>
                                                                        </button>
                                                                    </div>

                                                                    <div className="line-div">
                                                                    </div>

                                                                    <div className="new-york-parent">
                                                                        {/* <div className="new-york"></div> */}
                                                                        <div className="location-on-parent">
                                                                            <img className="location-on-icon" alt="" src="./assets/img/location_on.svg" />
                                                                            <div className="manhattan">{selectedRestroom.address}</div>
                                                                        </div>
                                                                    </div>
                                                                    {selectedRestroom.openHours?.length > 0 ?

                                                                        <div className="frame-parent4">
                                                                            <div className="clock-parent">
                                                                                {/* <img className="clock-icon" alt="" src="./assets/img/Clock.svg" /> */}

                                                                                <img className="vector-icon7" alt="" src="./assets/img/Clock.svg" />
                                                                                {selectedRestroom.openHours?.some(hour =>
                                                                                    hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                                ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div">Closed</div>}
                                                                            </div>
                                                                            <div className="frame-parent5">
                                                                                {
                                                                                    displayOpenHours(selectedRestroom.openHours)
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        : null}

                                                                    <div className="line-div">
                                                                    </div>
                                                                    <div className="frame-wrapper1">
                                                                        <div className="reviews-container">
                                                                            <b className="reviews1">Reviews</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="frame-parent13">
                                                                        <div className="divblock-parent">

                                                                            <Reviews restRoom={selectedRestroom?.id} selectedRestroom={selectedRestroom} />
                                                                        </div>
                                                                    </div>




                                                                </div>
                                                                <div className="vector-wrapper">
                                                                    <img className="vector-icon10" alt="" src="./assets/img/back.svg" onClick={() => { setSelectedRestroom(null); handleBackClick() }} id="vectorIcon" />
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </Col>
                                        <Col md={12} lg={5} sm={12} style={{ border: "2px solid black" }}>


                                        </Col>



                                    </Row>
                                </Container>

                            ) : selectedRestroom && (
                                <>
                                    {
                                        filteredRestrooms ?
                                            filteredRestrooms?.length <= 0 ?
                                                <Container fluid className={`changecontainer ${activeButton === 'map' ? 'mape' : null}`} >

                                                    <Row>
                                                        <Col lg={8} md={12} sm={12}>

                                                            <div className="empty">
                                                                <img src="./assets/img/gif.gif" alt="" />
                                                                <h3>Sorry,
                                                                    <br />
                                                                    there are no restrooms in this area</h3>
                                                                <a className="nav-link addRest" onClick={() => { window.location.href = "/AddNew" }}>
                                                                    <img src="./assets/Plus.svg" alt="" />Add
                                                                    Restroom
                                                                </a>
                                                                <p>
                                                                    Help people reach the nearest restroom to them by adding a restroom now!
                                                                </p>
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                </Container>
                                                :
                                                <Container fluid className={`changecontainer ${activeButton === 'map' ? 'mape' : null}`} >

                                                    <Row>

                                                        <Col lg={7} md={12} sm={12}>
                                                            <Row className={` ${isActivee ? 'active' : ''}`}>
                                                                {windowidth > 1024 ?
                                                                    <p style={{ paddingLeft: "5px" }}>Result: {totalResults} Places</p>
                                                                    : null}
                                                                <div className="divline" onClick={toggleClass}></div>
                                                                <div className="mobonly">
                                                                    <input type="checkbox" name="nearby" id="nearby" checked={checkboxes.nearby}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <label className="gislocation-poi-o-parent" htmlFor="nearby">
                                                                        <img className="gislocation-poi-o-icon" alt="" src="./assets/img/location_on.svg" />
                                                                        <img className="gislocation-poi-o-icon checked" alt="" src="./assets/img/gis_location-poi-o-checked.svg" />

                                                                        <div className="nearby">Nearby</div>
                                                                    </label>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="result-9">Result : {totalResults} places</div>

                                                                        <div className="d-flex justify-content-end">
                                                                            <button className={`component-143 ${activeButton === 'cards' ? 'iconnnn' : ''}`} onClick={() => handleButtonClick('cards')} id="component143Container">
                                                                                <img className="view-agenda-icon" alt="" src="./assets/view_agenda.svg" />
                                                                                <div className="cards">Cards</div>
                                                                            </button>
                                                                            <button className={`component-143 ${activeButton === 'map' ? 'iconnnn' : ''}`} onClick={() => handleButtonClick('map')} id="iconContainer">

                                                                                <img className="sliders-horizontal-icon" alt="" src="./assets/location_on (1).svg" />
                                                                                <div className="background">
                                                                                    Map
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {filteredRestrooms
                                                                    .map((filterm) => (
                                                                        <Col md="6" lg="4" sm="12" className="cardrestroom" key={filterm.id}>
                                                                            <div className="rectangle-parent" onClick={() => handleMoreDetailsClick(filterm)}>
                                                                                {/* Swiper for Photos */}
                                                                                <button className="component-14" onClick={() => handleAddToFavorite(filterm.id)}>
                                                                                    {isFavorited[filterm.id] ? (
                                                                                        <img src="./assets/img/star-fill.svg" alt="Not Favorited" />

                                                                                    ) : (
                                                                                        <img src="./assets/img/star-empty.svg" alt="Favorited" />

                                                                                    )}

                                                                                </button>
                                                                                {/* {filterm.photos.length > 0 && ( */}
                                                                                <Swiper
                                                                                    spaceBetween={10}
                                                                                    slidesPerView={1}
                                                                                    pagination={{ clickable: true }}
                                                                                    autoplay={{ delay: 3000 }}
                                                                                    className="photo-swiper"
                                                                                    navigation={true}
                                                                                    modules={[FreeMode, Navigation, Pagination]}

                                                                                >
                                                                                    {filterm.photos?.length == 0 ?
                                                                                        <SwiperSlide>
                                                                                            <img src="assets/img/noimg.png" alt="Restroom" className="restroom-photo" />
                                                                                        </SwiperSlide>
                                                                                        :
                                                                                        filterm.photos.map(photo => (
                                                                                            <SwiperSlide key={photo.id}>
                                                                                                <img src={photo.url} alt="Restroom" className="restroom-photo" />
                                                                                            </SwiperSlide>
                                                                                        ))}
                                                                                </Swiper>
                                                                                {/* )} */}
                                                                                <div className="frame-parent">

                                                                                    <div className="frame-group d-flex justify-content-between flex-wrap">
                                                                                        <div className="manx-museum-wrapper">
                                                                                            <div
                                                                                                className={`manx-museum ${filterm.name.length > 15 ? 'hoverexceed' : ''} `}
                                                                                                title={filterm.name.length > 15 ? filterm.name : ''} data-fullname={filterm.name.length > 15 ? filterm.name : ''}
                                                                                            >
                                                                                                {filterm.name.length > 15 ? `${filterm.name.slice(0, 15)}...` : filterm.name}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100">
                                                                                        <div className="frame-container">
                                                                                            <div className="manhattan-wrapper">
                                                                                                <div className="miles-from-your">{filterm.address}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="frame-wrapper ml-auto">
                                                                                            <div className="parent">
                                                                                                {/* <div className="div">5</div> */}

                                                                                                <div className="ratings" id="ratingsText">
                                                                                                    {Math.ceil(filterm.avgRating) > 0 ? (
                                                                                                        <div className="star-wrapper">
                                                                                                            <div className="star">
                                                                                                                {[...Array(Math.ceil(filterm.avgRating))].map((_, index) => (
                                                                                                                    <img
                                                                                                                        key={index}
                                                                                                                        className="vector-icon1"
                                                                                                                        alt=""
                                                                                                                        src="./assets/img/search/star.svg"
                                                                                                                    />
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : null}
                                                                                                    <span className="ratings-txt-container">
                                                                                                        {/* <span> </span> */}

                                                                                                        (<span className="ratings1">{filterm.avgRating} ratings</span>)
                                                                                                        {/* <span> </span> */}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="frame-div justify-content-between" >
                                                                                        <div className="closed-parent">
                                                                                            <>
                                                                                                {filterm.openHours?.some(hour =>
                                                                                                    hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                                                ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div"> Closed</div>}
                                                                                            </>
                                                                                            {/* <div className="frame-item"></div>
                                                                                              <div className="opens-at-0930">Opens at 09:30</div> */}
                                                                                        </div>
                                                                                        <button className=" mobdirectionbtn" onClick={() => handleButtonClick('map')}>
                                                                                            <div className="">
                                                                                                <img className="" alt="" src="./assets/img/arrow_top_right (1).svg" />
                                                                                            </div>
                                                                                            <div className="">Directions</div>

                                                                                        </button>
                                                                                        {/* <div className="more-details-wrapper" id="frameContainer1">
                                                                                            <div className="more-details">More details</div>
                                                                                        </div> */}
                                                                                    </div>


                                                                                </div>
                                                                                {filterm.isFree == 'FALSE' ?
                                                                                    <div className="rectangle-group">
                                                                                        <div className="group-child"></div>
                                                                                        <div className="paid">Paid</div>
                                                                                        <div className="vector-wrapper">
                                                                                            <img className="vector-icon7" alt="" src="./assets/img/dolla4r.svg" />
                                                                                        </div>
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </Col>
                                                                    ))}
                                                            </Row>
                                                        </Col>
                                                        <Col md={12} lg={5} sm={12} style={{ border: "2px solid black" }}>
                                                            {/* {!selectedRestroom ?


                                            <LoadScript googleMapsApiKey="AIzaSyBe5BMVRInlmcHJdx9ZQ8yn89U9PyG54kE">
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    center={searchLocation}
                                                    zoom={15}
                                                    onClick={(e) => handleMapClick(e)}
                                                >
                                                    {filteredRestrooms.map((restroom, index) => (
                                                        <Marker
                                                            key={index}
                                                            position={{ lat: parseFloat(restroom.latitude), lng: parseFloat(restroom.longitude) }}
                                                            onClick={() => handleMoreDetailsClick(restroom)}
                                                        />
                                                    ))}
                                                    {selectedPosition && (
                                                        <Marker
                                                            position={selectedPosition}
                                                            icon={{
                                                                url: './assets/img/Logo_Header.svg'
                                                            }}
                                                        />
                                                    )}
                                                </GoogleMap>
                                            </LoadScript>
                                            : null} */}
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            : null
                                    }


                                    <Container fluid className="roomSelected">
                                        <Row>

                                            <Col lg={7} md={12} >
                                                <div>

                                                    <div
                                                        className={`tabdiv ${isActivee ? 'active' : ''} room-details-container`}
                                                        ref={tabDivRef}
                                                        style={{ position: 'absolute', top: '450px', left: '0', right: '0' }}
                                                        onMouseDown={handleDragStart}
                                                        onTouchStart={handleDragStart}
                                                    >
                                                        {results ?
                                                            <div className=" divline divnotline" onMouseDown={handleDragStart} onClick={viewDrag} onTouchStart={handleDragStart} style={{ cursor: 'grab' }}>View Results</div>

                                                            : <div className="divline" onMouseDown={handleDragStart} onTouchStart={handleDragStart} style={{ cursor: 'grab' }}></div>}



                                                        <div className="frame-parent">
                                                            <div className="frame-wrapper">
                                                                <div className="frame-container">
                                                                    <div className="rectangle-parent">

                                                                        {selectedRestroom.photos?.length == 0 ?
                                                                            <div className="d-flex align-items-center">
                                                                                <img className="frame-child" alt="" src="assets/img/noimg.png" />
                                                                                <button className="component-14" onClick={() => handleAddToFavorite(selectedRestroom.id)}>
                                                                                    {isFavorited[selectedRestroom.id] ? (
                                                                                        <img src="./assets/img/Frame (1).svg" alt="Not Favorited" className="w-100" />

                                                                                    ) : (
                                                                                        <img src="./assets/img/Frame.svg" alt="Favorited" className="w-100" />

                                                                                    )}

                                                                                </button>
                                                                                <div>
                                                                                    <button className="addBtnPhoto" onClick={openModal}>
                                                                                        <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                        <div className="add-photos">Add photos</div>
                                                                                    </button>
                                                                                    <br />
                                                                                    <button className="addBtnPhoto" onClick={() => { window.location.href = `/edit-room/${selectedRestroom.id}` }}>
                                                                                        <MdEdit className="add-photo-alternate-icon" />
                                                                                        <div className="add-photos">Add Update</div>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            : selectedRestroom.photos?.length == 1 ? (
                                                                                <div className="d-flex align-items-center">
                                                                                    <img className="frame-child" alt="" src={selectedRestroom.photos[0].url} />
                                                                                    <button className="component-14 " onClick={() => handleAddToFavorite(selectedRestroom.id)}>
                                                                                        {isFavorited[selectedRestroom.id] ? (
                                                                                            <img src="./assets/img/Frame (1).svg" alt="Not Favorited" className="w-100" />

                                                                                        ) : (
                                                                                            <img src="./assets/img/Frame.svg" alt="Favorited" className="w-100" />

                                                                                        )}

                                                                                    </button>
                                                                                    <div>
                                                                                        <button className="addBtnPhoto" onClick={openModal}>
                                                                                            <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                            <div className="add-photos">Add photos</div>
                                                                                        </button>
                                                                                        <br />
                                                                                        <button className="addBtnPhoto" onClick={() => { window.location.href = `/edit-room/${selectedRestroom.id}` }}>
                                                                                            <MdEdit className="add-photo-alternate-icon" />
                                                                                            <div className="add-photos">Add Update</div>
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <Swiper
                                                                                        loop={true}
                                                                                        spaceBetween={20}
                                                                                        className="mySwiper2"
                                                                                        pagination={true}
                                                                                        freeMode={true}
                                                                                        slidesPerView={1.30}

                                                                                        modules={[Pagination, FreeMode]}
                                                                                    >
                                                                                        {selectedRestroom.photos?.map((index) => (
                                                                                            <SwiperSlide key={index}>
                                                                                                <button className="component-14 swiperbtnadd" onClick={() => handleAddToFavorite(selectedRestroom.id)}>
                                                                                                    {isFavorited[selectedRestroom.id] ? (
                                                                                                        <img src="./assets/img/Frame (1).svg" alt="Not Favorited" className="w-100" />

                                                                                                    ) : (
                                                                                                        <img src="./assets/img/Frame.svg" alt="Favorited" className="w-100" />

                                                                                                    )}

                                                                                                </button>
                                                                                                <img src={index.url} alt={`Photo ${index + 1}`} />
                                                                                            </SwiperSlide>
                                                                                        ))}
                                                                                        <SwiperSlide>
                                                                                            <div>
                                                                                                <button className="addBtnPhoto" onClick={openModal}>
                                                                                                    <img className="add-photo-alternate-icon" alt="" src="./assets/img/add_photo_alternate.svg" />
                                                                                                    <div className="add-photos">Add photos</div>
                                                                                                </button>
                                                                                                <br />
                                                                                                <button className="addBtnPhoto" onClick={() => { window.location.href = `/edit-room/${selectedRestroom.id}` }}>
                                                                                                    <MdEdit className="add-photo-alternate-icon" />
                                                                                                    <div className="add-photos">Add Update</div>
                                                                                                </button>
                                                                                            </div>

                                                                                        </SwiperSlide>


                                                                                    </Swiper>

                                                                                </>
                                                                            )}

                                                                        <div className="d-flex justify-content-between w-100 my-2 flex-wrap">

                                                                            <div className="stepney-city-farm">{selectedRestroom.name}</div>

                                                                            <div className="frame-div">
                                                                                <div className="frame-parent1">

                                                                                    <div className="star">
                                                                                    </div>
                                                                                    <div className="vector-group">
                                                                                        {Math.ceil(selectedRestroom.avgRating) > 0 ?
                                                                                            <>
                                                                                                {[...Array(Math.ceil(selectedRestroom.avgRating))].map((_, index) => (
                                                                                                    <div className="star1">
                                                                                                        <img className="vector-icon1" alt="" src="./assets/img/search/star.svg" />
                                                                                                    </div>
                                                                                                ))}
                                                                                            </> : null}
                                                                                    </div>
                                                                                    <div className="wrapper">
                                                                                        <div className="wheelchair">({selectedRestroom.avgRating} rating )</div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="sports-complex">{selectedRestroom.address}</div> */}
                                                                        {/* <div className="frame-parent2">
                                                                            <div className="overview-wrapper">
                                                                                <div className="wheelchair">Overview </div>
                                                                            </div>
                                                                            <div className="reviews-wrapper" id="frameContainer1">
                                                                                <div className="wheelchair">Reviews</div>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="line-div">
                                                                        </div>
                                                                        <div className="d-flex mr-5 justify-content-center">
                                                                            <div className="component-14-parent marrr">

                                                                                <button className="component-14" onClick={() => handleAddToFavorite(selectedRestroom.id)}>
                                                                                    {isFavorited[selectedRestroom.id] ? (
                                                                                        <img src="./assets/img/Frame (1).svg" alt="Not Favorited" className="w-100" />

                                                                                    ) : (
                                                                                        <img src="./assets/img/Frame.svg" alt="Favorited" className="w-100" />

                                                                                    )}

                                                                                </button>
                                                                                <div className="wheelchair">Favorite</div>
                                                                            </div>
                                                                            <button className="frame-parent3" onClick={handleGetDirections} >
                                                                                <div className="group-wrapper">
                                                                                    <div className="arrow-top-right-wrapper">
                                                                                        <div className="arrow-top-right">
                                                                                            <img className="arrow-top-right-icon" alt="" src="./assets/img/arrow_top_right.svg" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="wheelchair">Directions</div>
                                                                            </button>
                                                                        </div> */}
                                                                        {/* 
                                                                        <div className="line-div">
                                                                        </div> */}
                                                                        <div className="d-flex align-items-baseline w-100 justify-content-between ">
                                                                            <div className="new-york-parent">
                                                                                {/* <div className="new-york"></div> */}
                                                                                <div className="location-on-parent w-75">
                                                                                    <img className="location-on-icon" alt="" src="./assets/img/location_on.svg" />
                                                                                    <div className="manhattan text-left">{selectedRestroom.address}</div>
                                                                                </div>

                                                                            </div>
                                                                            <button className="mobdirectionbtn" onClick={() => { handleButtonClick('map'); handleGetDirections() }}><div className=""><img className="" alt="" src="./assets/img/arrow_top_right (1).svg" /></div><div className="">Directions</div></button>
                                                                        </div>
                                                                        {selectedRestroom.openHours?.length > 0 ?

                                                                            <div className="frame-parent4">
                                                                                <div className="clock-parent">
                                                                                    <img className="clock-icon" alt="" src="./assets/img/Clock.svg" />
                                                                                    {selectedRestroom.openHours?.some(hour =>
                                                                                        hour.day === currentDay && hour.isOpen && currentTime >= hour.startTime && currentTime <= hour.endTime
                                                                                    ) ? <div className="div" style={{ color: "green" }}>Open</div> : <div className="div">Closed</div>}
                                                                                </div>
                                                                                <div className="frame-parent5">
                                                                                    {displayOpenHours(selectedRestroom.openHours)}


                                                                                </div>
                                                                            </div>
                                                                            : null}

                                                                        <div className="line-div">
                                                                        </div>
                                                                        <div className="frame-wrapper1">
                                                                            <div className="reviews-container">
                                                                                <b className="reviews1">Reviews</b>
                                                                            </div>
                                                                        </div>
                                                                        <div className="frame-parent13">
                                                                            <div className="divblock-parent">

                                                                                {/* {fetchReviewsRestrooms()} */}
                                                                                <Reviews restRoom={selectedRestroom.id} selectedRestroom={selectedRestroom} />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div className="vector-wrapper">
                                                                        <img className="vector-icon10" alt="" src="./assets/img/back.svg" onClick={() => { setSelectedRestroom(null); handleBackClick() }} id="vectorIcon" />
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </Col>

                                            <Col md={12} lg={5} sm={12} style={{ border: "2px solid black" }}>

                                                {/* <LoadScript googleMapsApiKey="">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedRestroom ? { lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) } : initialCenter}
                zoom={15}
            >
                <Marker
                    position={{ lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) }}
                />
            </GoogleMap>
        </LoadScript> */}
                                                {/* <LoadScript googleMapsApiKey="AIzaSyBe5BMVRInlmcHJdx9ZQ8yn89U9PyG54kE">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedRestroom ? { lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) } : initialCenter}
                zoom={15}
            >

                <Marker
                    position={{ lat: parseFloat(selectedRestroom.latitude), lng: parseFloat(selectedRestroom.longitude) }}
                    icon={{
                        url: './assets/img/Logo_Header.svg'
                    }}
                />
             
            </GoogleMap>
        </LoadScript> */}
                                            </Col>



                                        </Row>
                                    </Container>
                                </>

                            )

                        }
                    </div>

                </div>
            </div>

            <Modal show={modalIsOpen} onHide={closeModal} className="modelform">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Upload Photo</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleSubmit(e, selectedRestroom.id)}>
                        <input type="file" accept="image/*" onChange={handlePhotoUpload} required className="form-control" />
                        <br />
                        <Button variant="primary" type="submit" className="mt-2 ml-auto">
                            Add Photo
                        </Button>
                    </form>
                </Modal.Body>

            </Modal>

            <Footer />
        </>
    );
};

export default Home;