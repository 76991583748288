import React from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function ResetetPassSu() {


    return (
        <>
            <div className='reset-pass-success'>
                <img src="./assets/img/Success Icon.svg" alt="" />
                <h3>Success</h3>
                <p>Congratulations your password has <br />
                    been changed,</p>
                <Link to="/SignIn">Go to your profile</Link>

            </div>
            <Footer />
        </>
    );
}

export default ResetetPassSu;
