import React, { useEffect } from "react";
import Home from "./Home";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Navbarr from "./Navbarr";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import "swiper/css/pagination";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import About from "./About";
import Contact from "./Contact";
import AddNew from "./AddNew";
import Signup from "./Signup";
import SignIn from "./SignIn";
// import NotFound from "./NotFound";
import ForgetPass from "./ForgetPass";
import EditProfile from "./EditProfile";
import ContactDone from "./ContactDone";
import Favorite from "./Favorite";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPass from "./ResetPass";
import NotFound from "./NotFound";
import ResetetPassSu from "./ResetetPassSu";
import UpdateRoom from "./UpdateRoom";
import ReactGA from "react-ga";
const TRACKING_ID = "G-TRJ7KVSE9J";
ReactGA.initialize(TRACKING_ID);

function App() {



  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Root />}>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/AddNew" element={<AddNew />} />
          <Route path="/edit-room/:id" element={<UpdateRoom />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/ForgetPass" element={<ForgetPass />} />
          <Route path="/Profile" element={<EditProfile />} />
          <Route path="/ContactDone" element={<ContactDone />} />
          <Route path="/Favorite" element={<Favorite />} />
          <Route path="/reset-pass" element={<ResetPass />} />
          <Route path="/reset-pass-success" element={<ResetetPassSu />} /> 
          <Route path="*" element={<NotFound />} />

          
        </Route>
      </>
    )
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEAUTHPROVIDER}>

    <div className={`app`}>
      <RouterProvider router={router}></RouterProvider>
    </div>
    </GoogleOAuthProvider>
  );
}

export default App;

const Root = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.style.height = "unset";
    document.body.style.overflowY = "unset";

  }, [location]);


  return (
    <div className={`App`}>
      <ScrollToTop />

      <Navbarr />
      <Outlet />
    </div>
  );
};
