import React, { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Footer from './Footer';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { Button, Modal } from 'react-bootstrap';

function SignIn() {
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      document.body.style.height = "unset"
        document.body.style.overflowY = "unset"
    }
})
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [emailForReset, setEmailForReset] = useState("");
  const [resetMode, setResetMode] = useState(false); 
  const [showResetModal, setShowResetModal] = useState(false); 

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onResetInputChange = (e) => {
    setEmailForReset(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      window.location.href = "/";
    }
  }, [navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
      try {
        const payload = { email: user.email, password: user.password };
        const response = await axios.post("https://api.rerests.com/api/v1/auth/login", payload, {
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.data) {
          localStorage.setItem('authToken', response.data.data.access_token);
          localStorage.setItem('id', response.data.data.user.id);

          Swal.fire({
            icon:'success',
            title: 'Logged In Successfully',
            showConfirmButton: false,
            timer: 1500
          }).then(window.location.href = "/")


        } else {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: 'Please try again.'
          });
        }
      } catch (error) {
        console.error("Error during login:", error);
        if (error.response) {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: error.response.data.message || 'Please check your input and try again.'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: error.message
          });
        }
      }
  };
  // const Reset = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.post("https://api.rerests.com/api/v1/auth/forget-password", { email: user.email }, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //     });
  //     if (response) {
  //       navigate("/ForgetPass", { state: { email: user.email } }); 
  //     }


  //     setResetMode(false); 
  //   } catch (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Please Enter Valid Email',
  //     });
  //   }
  // }


  const handleGoogleLogin = async (GoogleResponse) => {
    const body = {
      idToken: GoogleResponse.credential,
    };
    try {
      const req = await fetch("https://api.rerests.com/api/v1/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      if (res.data.access_token) {
        localStorage.setItem('authToken', res.data.access_token);
        localStorage.setItem('id', res.data.user.id);
        localStorage.setItem('googlesigned', true);

        Swal.fire({ title: 'Signed In successfully.', icon: 'success', confirmButtonText: 'OK' });
        window.location.href = "/";
      } else {
        Swal.fire({ icon: 'error', title: 'Sign In Failed', text: 'Could not retrieve access token.' });
      }
    } catch (error) {
      console.error('Error during Google sign-up:', error);
      Swal.fire({ icon: 'error', title: 'Sign-up Error', text: error.response?.data?.message || 'An error occurred during sign-up.' });
    }
  };
  const ResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.rerests.com/api/v1/auth/forget-password', { email: emailForReset }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        navigate('/ForgetPass', { state: { email: emailForReset } });
        Swal.fire({ title: 'Password reset link sent!', icon: 'success' });
      }
      setShowResetModal(false); // Close the modal after reset
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter Valid Email',
      });
    }
  };
  
return (
  <>

    <div className='signup-signin '>
      <div className="login-sign-in">
        <div className="login-sign-in-inner">
          <div className="frame-parent">
            <div className="frame-group">
              <div className="sign-up-wrapper" id="frameContainer">
                <Link to="/Signup">  <div className="sign-up">Sign Up</div></Link>
              </div>
              <div className="sign-in-wrapper">
                <div className="sign-in">Sign In</div>
              </div>
            </div>
            <form onSubmit={(e) => onSubmit(e)} className="frame-wrapper">
              <div className="frame-container">
                <div className="frame-div">
                  <div className="frame-parent1">
                    <div className="frame-wrapper1">
                      <div className="frame-parent2">
                        <div className="frame-wrapper2">
                          <div className="email-wrapper">
                            <div className="sign-up">Email</div>
                          </div>
                        </div>

                        <div className="enter-your-email-here-wrapper">

                          <input className="enter-your-email" name="email"
                            value={user.email}
                            onChange={(e) => onInputChange(e)} placeholder="Enter your email here" type="email" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-wrapper3">
                      <div className="frame-parent2">
                        <div className="frame-wrapper2">
                          <div className="email-wrapper">
                            <div className="sign-up">Password</div>
                          </div>
                        </div>
                        <div className="enter-your-password-here-parent">
                          <div className="enter-your-password">
                            <input
                              name="password"
                              value={user.password}
                              onChange={(e) => onInputChange(e)}
                              className="password-password"
                              placeholder="Enter your password here"
                              type={isPasswordVisible ? "text" : "password"}
                              id="password0"
                            />
                          </div>
                          <img
                            className="eyeslash-icon"
                            alt=""
                            onClick={togglePasswordVisibility}
                            src={`./assets/img/${isPasswordVisible ? 'Eye.svg' : 'EyeSlash.svg'}`}
                            id="eyeicon"
                          />
                        </div>
                      </div>
                    </div>


                    <button style={{ backgroundColor: "white" }} type='button'                         onClick={() => setShowResetModal(true)} // Open the reset modal
>
                      <div className="forget-password-wrapper" id="frameContainer2">
                        <div className="forget-password">Forget password?</div>
                      </div>
                    </button>
                  </div>

                </div>
                <div className="frame-parent4">
                  <div className="frame-wrapper6 mt-2">
                    <button type='submit' className="sign-in-container" id="frameContainer">
                      <div className="sign-up">Sign In</div>
                    </button>
                  </div>
                  <div className="need-an-account-parent">
                    <div className="sign-up">Need an account?</div>
                    <Link to="/Signup">   <div className="create-one" id="createOneText">Create one</div> </Link>
                  </div>
                  <div className="frame-parent5">
                    <div className="or-wrapper">
                      <div className="sign-up">_ OR _</div>
                    </div>
                    <GoogleLogin
                      width={280}
                        onSuccess={handleGoogleLogin}
                      >
                    <button type='button' className="google-parent" id="frameContainer1" onClick={()=>handleGoogleLogin()}>
                      <img className="google-icon" alt="" src="./assets/img/Google.svg" />
                      <div className="sign-in-with">Sign in with Google</div>
                    </button>
                    </GoogleLogin>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
    <div className='passResetty'>
    <Modal className='passResetty' show={showResetModal} onHide={() => setShowResetModal(false)}>
     
        <Modal.Body className='modalReset'>
        <div className="sign-in-wrapper">
                <div className="sign-in">Sign In</div>
              </div>
          <form onSubmit={ResetPassword}>
            <div className='form-group'>
              <label htmlFor='emailForReset'>Enter your Email:</label>
              <input
                type='email'
                className='form-control'
                id='emailForReset'
                value={emailForReset}
                onChange={onResetInputChange}
                placeholder='Enter your email'
                required
              />
            </div>
            <Button variant='primary' type='submit' className='mt-3'>
              Send Reset Link
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      </div>
    <Footer />
  </>
)
}

export default SignIn
