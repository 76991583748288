import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Footer from './Footer';

function ForgetPass() {
    const location = useLocation();
    const email = location.state?.email || "";  // Retrieve email from the location state
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"
        }
    })
    const handleResubmit = async () => {
        try {
            const response = await axios.post("https://api.rerests.com/api/v1/auth/forget-password", 
              { email: email }, 
              { headers: { 'Content-Type': 'application/json' } }
            );
            if (response) {
                Swal.fire({
                    title: 'Email Sent Again!',
                    text: 'Please check your email for the reset link.',
                    icon: 'success',
                    timer: 1500
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to resend email. Please try again later.'
            });
        }
    };

    return (
        <>
            <div className='re-rest-pass'>
                <div className="send-link" id="sendLinkContainer">
                    <div className="send-message-1-1-parent">
                        <img className="send-message-1-1" alt="" src="./assets/img/Group.svg" />
                        <div className="we-sent-you">We sent you a link in your email to change your password.</div>
                        <div className="please-check-your-container">
                            <p className="please-check-your">Please check your email to create </p>
                            <p className="please-check-your">a new password</p>
                        </div>
                        <div className="cant-get-email-container">
                            <span>Can't get the email? </span>
                            <span className="resubmit" onClick={handleResubmit}>Resubmit</span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForgetPass;
