import React, { useEffect, useState } from 'react'

// import "./css/main.css"
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Footer from './Footer';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

function Signup() {
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      document.body.style.height = "unset"
        document.body.style.overflowY = "unset"
    }
})
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);

  const togglePasswordVisibility2 = () => {
    setIsPasswordVisible2(!isPasswordVisible2);
  };
  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      window.location.href = "/"
    }
  }, []);


  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "", // New state for confirm password
  });

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirm_password) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please check and try again.'
      });
      return;
    }
    try {
      const payload = {
        name: user.name,
        email: user.email,
        password: user.password,
      };


      const response = await axios.post("https://api.rerests.com/api/v1/auth/register", payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        Swal.fire({
          icon: "success",
          title: `Submitted Successfully`,
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: 'Please try again.'
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: error.response.data.message || 'Please check your input and try again.'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: error.message
        });
      }
    }
  };
  const handleGoogleLogin = async (GoogleResponse) => {
    const body = {
      idToken: GoogleResponse.credential,
    };
    try {
      const req = await fetch("https://api.rerests.com/api/v1/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      if (res.data.access_token) {
        localStorage.setItem('authToken', res.data.access_token);
        localStorage.setItem('id', res.data.user.id);
        localStorage.setItem('googlesigned', true);

        Swal.fire({ title: 'Signed up successfully.', icon: 'success', confirmButtonText: 'OK' });
        window.location.href = "/";
      } else {
        Swal.fire({ icon: 'error', title: 'Sign-up Failed', text: 'Could not retrieve access token.' });
      }
    } catch (error) {
      console.error('Error during Google sign-up:', error);
      Swal.fire({ icon: 'error', title: 'Sign-up Error', text: error.response?.data?.message || 'An error occurred during sign-up.' });
    }
  };



  return (
    <>
      <div className='signup-signin '>

        <div className="login-sign-in signup">
          <div className="login-sign-in-inner">
            <div className="frame-parent">
              <div className="frame-group">
                <div className="sign-in-wrapper" id="frameContainer">
                  <div className="sign-in">Sign Up</div>
                </div>
                <div className="sign-up-wrapper signinnnn">
                  <Link to="/SignIn"><div className="sign-up ">Sign In</div></Link>
                </div>
              </div>
              <form onSubmit={(e) => onSubmit(e)} className="frame-wrapper">
                <div className="frame-container">
                  <div className="frame-div">
                    <div className="frame-parent1">
                      <div className="frame-wrapper1 ">
                        <div className="frame-parent2">
                          <div className="frame-wrapper2">
                            <div className="email-wrapper">
                              <div className="sign-up">Full Name</div>
                            </div>
                          </div>

                          <div className="enter-your-email-here-wrapper">

                            <input className="enter-your-email" name="name"
                              value={user.name}
                              onChange={(e) => onInputChange(e)} placeholder="Enter your full name here" type="text" />

                          </div>
                        </div>
                        <div className="frame-parent2">
                          <div className="frame-wrapper2">
                            <div className="email-wrapper">
                              <div className="sign-up">Email</div>
                            </div>
                          </div>

                          <div className="enter-your-email-here-wrapper">
                            <input className="enter-your-email" name="email"
                              value={user.email}
                              onChange={(e) => onInputChange(e)} placeholder="Enter your email here" type="email" />

                          </div>
                        </div>
                      </div>

                      <div className="frame-wrapper3">
                        <div className="frame-parent2">
                          <div className='d-flex mt-1'>
                            <div>
                              <div className="frame-wrapper2">
                                <div className="email-wrapper">
                                  <div className="sign-up">Password</div>
                                </div>
                              </div>
                              <div className="enter-your-password-here-parent">
                                <div className="enter-your-password">
                                  <input
                                    name="password"
                                    value={user.password}
                                    onChange={(e) => onInputChange(e)}
                                    className="password-password"
                                    placeholder="Enter your password here"
                                    type={isPasswordVisible ? "text" : "password"}
                                    id="password0"
                                  />
                                </div>
                                <img
                                  className="eyeslash-icon"
                                  alt=""
                                  onClick={togglePasswordVisibility}
                                  src={`./assets/img/${isPasswordVisible ? 'Eye.svg' : 'EyeSlash.svg'}`}
                                  id="eyeicon"
                                />
                              </div>
                            </div>


                            <div>
                              <div className="frame-wrapper2">
                                <div className="email-wrapper">
                                  <div className="sign-up"> Confirm Password</div>
                                </div>
                              </div>
                              <div className="enter-your-password-here-parent">
                                <div className="enter-your-password">
                                  <input
                                    className="password-password"
                                    placeholder="Enter your password again"
                                    name="confirm_password"
                                    value={user.confirm_password}
                                    onChange={(e) => onInputChange(e)}
                                    type={isPasswordVisible2 ? "text" : "password"}
                                  />
                                </div>
                                <img
                                  className="eyeslash-icon"
                                  alt=""
                                  onClick={togglePasswordVisibility2}
                                  src={`./assets/img/${isPasswordVisible2 ? 'Eye.svg' : 'EyeSlash.svg'}`}
                                  id="eyeicon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="frame-parent4">
                    <div className="frame-wrapper6">
                      <button type='submit' className="sign-in-container" id="frameContainer">
                        <div className="sign-up">Sign Up</div>
                      </button>
                    </div>
                    <div className="need-an-account-parent">
                      <div className="sign-up">Already have an account?</div>
                      <Link to="/SignIn"> <div className="create-one" id="createOneText">Sign in</div> </Link>
                    </div>
                    <div className="frame-parent5">
                      <div className="or-wrapper">
                        <div className="sign-up">_ OR _</div>
                      </div>
                      {/* <button type="button" onClick={() => googleLogin()} className="google-parent" id="frameContainer1">
                        <img className="google-icon" alt="" src="./assets/img/Google.svg" />
                        <div className="sign-in-with">Sign up with Google</div>
                      </button> */}
                      <GoogleLogin
                      width={280}
                        onSuccess={handleGoogleLogin}
                      >
                        <button type="button" className="google-parent">
                          <img className="google-icon" alt="" src="./assets/img/Google.svg" id="frameContainer1" />
                          <div className="sign-in-with">Sign up with Google</div>
                        </button>
                      </GoogleLogin>

                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

        </div>
      </div>

      <Footer />

    </>
  )
}

export default Signup
