import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Dropdown, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import axios from "axios";

function NavBar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [name, setName] = useState("");
  const [profileUrl, setProfileUrl] = useState("./assets/img/profile.png");
  const [email, setEmail] = useState("");
  const windowidth = window.innerWidth
  useEffect(() => {
    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('authToken'); // Retrieve the token if needed
    if (userId) {
      axios.get(`https://api.rerests.com/api/v1/user/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          setEmail(response.data.data.email);
          setName(response.data.data.name);

            
            if(response.data.data.image){
              setProfileUrl(response.data.data.image);

            }
          

        })
        .catch(error => {
        });
    }
  }, []);

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {

      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
  const location = useLocation(); 
  const imagePath = location.pathname.includes("/edit-room/") ? "../assets/img/Logo_Header.svg" : "./assets/img/Logo_Header.svg";

  return (
    <>
      <Navbar expand="xl" className="bg-body-tertiary navbar navbar-expand-xl bg-body-tertiary">
        <Container fluid className="d-flex justify-content-between align-items-center">
          <Navbar.Brand className="navbar-brand">
            <NavLink to="/" className="ImgNav" onClick={() => { window.location.href = "/" }}>
              <img src={imagePath} alt="" /> <span>ReRest</span>

            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            className="textttt"
            aria-controls="offcanvasNavbar"
            onClick={()=>setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={handleCloseOffcanvas}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="justify-content-between"
          >
            {windowidth < 1024 ?

              <>
                <Offcanvas.Body className="textttt mobile" id="navbarSupportedContent">
                  <Nav className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-between w-100 align-items-center">
                    <NavItem>
                      {
                        localStorage.getItem("authToken") ?

                          <>
                            <div className="profileNav">
                              <img className="urlphoto" src={profileUrl} alt="" />
                              <div>
                                <h3>{name}</h3>
                                <p>{email}</p>
                                <NavLink to="/Profile" className="loginBtn edit mr-auto" onClick={handleCloseOffcanvas}>Edit profile</NavLink>


                              </div>
                            </div>
                            <NavItem >
                              <NavLink className="nav-link addRest add" onClick={() => { window.location.href = "/AddNew" }} >
                                <img src={location.pathname.includes("/edit-room/") ?"../assets/img/Plus.svg": "./assets/img/Plus.svg"} alt="" />Add
                                Restroom
                              </NavLink>
                            </NavItem>
                            <NavItem onClick={() => { window.location.href = "/" }}>
                              <NavLink className="nav-link addRest find" to="/"> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/tele.svg":"./assets/img/tele.svg"} alt="" />
                                Find a Restroom
                              </NavLink>
                            </NavItem>
                          </>

                          : <>


                            <NavItem >
                              <NavLink className="nav-link addRest" to="/SignIn" onClick={handleCloseOffcanvas}> <img src={location.pathname.includes("/edit-room/") ?"../Vector(47).svg":"./Vector(47).svg"} alt="" />
                                Login
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink className="nav-link addRest find " to="/SignIn" onClick={handleCloseOffcanvas}>
                                <img src={location.pathname.includes("/edit-room/") ?"../assets/Plus.svg":"./assets/Plus.svg"} alt="" />Add
                                Restroom
                              </NavLink>
                            </NavItem>
                          </>

                      }

                    </NavItem>


                    <div className="d-flex  align-items-center mobflex">
                      <div className="navBorder"></div>
                      {
                        localStorage.getItem("authToken") ?
                          <NavItem>
                            <NavLink to="/Favorite" onClick={handleCloseOffcanvas}><img src={location.pathname.includes("/edit-room/") ?"../assets/img/star.svg":"./assets/img/star.svg"} alt="" className="navicon1" /> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/favoritenav.svg":"./assets/img/favoritenav.svg"} alt="" className="navicon" />FAVORITES</NavLink>

                          </NavItem>
                          :
                          <NavItem>
                            <NavLink to="/SignIn" onClick={handleCloseOffcanvas}><img src={location.pathname.includes("/edit-room/") ?"../assets/img/star.svg":"./assets/img/star.svg"} alt="" className="navicon1" /> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/favoritenav.svg":"./assets/img/favoritenav.svg"} alt="" className="navicon" />FAVORITES</NavLink>

                          </NavItem>
                      }
                      <NavItem>
                        <NavLink className="nav-link" to="/Contact" onClick={handleCloseOffcanvas}><img src={location.pathname.includes("/edit-room/") ?"../assets/img/contactnav.svg":"./assets/img/contactnav.svg"} alt="" className="navicon" /> Contact </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className="nav-link" to="/About" onClick={handleCloseOffcanvas}><img src={location.pathname.includes("/edit-room/") ?"../assets/img/aboutnav.svg":"./assets/img/aboutnav.svg"} alt="" className="navicon" /> About us </NavLink>
                      </NavItem>
                      <div className="navBorder"></div>

                    </div>
                    {
                      localStorage.getItem("authToken") ?
                        <NavItem className="logout" onClick={handleLogout}><span >  <img src={location.pathname.includes("/edit-room/") ?"../assets/img/logout.svg":"./assets/img/logout.svg"} /> LOG OUT</span></NavItem>
                        : null}


                  </Nav>
                </Offcanvas.Body>
              </> : 
              <>
                <Offcanvas.Body className="textttt" id="navbarSupportedContent">

                  <Nav className="navbar-nav me-auto mb-2 mb-lg-0 d-flex w-100 align-items-center">
                    {
                      !localStorage.getItem("authToken") ?
                        <div className="d-flex  align-items-center navbtn">
                          <NavItem >
                            <NavLink className="nav-link addRest add" to="/SignIn" onClick={handleCloseOffcanvas}>

                              <img src={location.pathname.includes("/edit-room/") ?"../assets/img/Plus.svg":"./assets/img/Plus.svg"} alt="" />Add
                              Restroom
                            </NavLink>
                          </NavItem>

                          <NavItem >
                            <NavLink className="nav-link addRest find" to="/" onClick={handleCloseOffcanvas}> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/tele.svg":"./assets/img/tele.svg"} alt="" />
                              Find a Restroom
                            </NavLink>
                          </NavItem>
                        </div>
                        :
                        <div className="d-flex  align-items-center navbtn">
                          <NavItem>
                            <NavLink className="nav-link addRest add" onClick={() => { window.location.href = "/AddNew" }}>

                              <img src={location.pathname.includes("/edit-room/") ?"../assets/img/Plus.svg":"./assets/img/Plus.svg"} alt="" />Add
                              Restroom
                            </NavLink>
                          </NavItem>

                          <NavItem >
                            <NavLink className="nav-link addRest find" to="/" onClick={() => { window.location.href = "/" }}> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/tele.svg":"./assets/img/tele.svg"} alt="" />
                              Find a Restroom
                            </NavLink>
                          </NavItem>
                        </div>
                    }
                    <div className="d-flex  align-items-center">
                      <NavItem>
                        <NavLink className="nav-link contact-link" to="/Contact" onClick={handleCloseOffcanvas}>Contact </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className="nav-link about-link" to="/About" onClick={handleCloseOffcanvas}>About us </NavLink>
                      </NavItem>

                    </div>

                    <NavItem>
                      {
                        localStorage.getItem("authToken") ?

                          <NavDropdown title={<img className="urlphoto" src={profileUrl} alt="" />} id="basic-nav-dropdown">
                            <Dropdown.Item smooth onClick={handleCloseOffcanvas}>
                              <div className="profileNav">
                                <img className="urlphoto" src={profileUrl} alt="" />
                                <div>
                                  <h3>{name?name:null}</h3>
                                  <p>{email?email:null}</p>
                                  <NavLink to="/Profile" className="loginBtn edit mr-auto" onClick={handleCloseOffcanvas}>Edit profile</NavLink>


                                </div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>

                            <Dropdown.Item smooth onClick={handleCloseOffcanvas}>
                              <NavLink to="/Favorite"><img src={location.pathname.includes("/edit-room/") ?"../assets/img/star.svg":"./assets/img/star.svg"} /> FAVORITES</NavLink>
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item onClick={handleLogout}><span >  <img src={location.pathname.includes("/edit-room/") ?"../assets/img/logout.svg":"./assets/img/logout.svg"} /> LOG OUT</span></Dropdown.Item>
                          </NavDropdown>

                          : <NavLink className="nav-link loginBtn" to="/SignIn" onClick={handleCloseOffcanvas}> <img src={location.pathname.includes("/edit-room/") ?"../assets/img/Vector.svg":"./assets/img/Vector.svg"} />
                            Login
                          </NavLink>
                      }

                    </NavItem>

                  </Nav>
                </Offcanvas.Body>
              </>}

          </Navbar.Offcanvas>
        </Container>
      </Navbar>


    </>
  );
}

export default NavBar;
