import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";
function Reviews({ restRoom, selectedRestroom }) {
    const [reviewss, setReviewss] = useState([]);
    const [avgRating, setAvgRating] = useState(0);
    const [ratingCounts, setRatingCounts] = useState({});
    const [totalReviews, setTotalReviews] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [profileUrl, setProfileUrl] = useState("./assets/img/profile.png");
    const [name, setName] = useState("");

    const [showModal, setShowModal] = useState(false);

    const [reviews, setReviews] = useState({ message: "", rating: 0 });



    const { rating, message } = reviews;

    const onRatingChange = (newRating) => {
        setReviews({ ...reviews, rating: newRating });
    };
    const onInputChange = (e) => {
        setReviews({ ...reviews, [e.target.name]: e.target.value });
    };


    const submit = async (e, restRoomId) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('authToken');
            if (token) {
                let formData = new FormData();
                formData.append("comment", message);
                formData.append("rating", rating);
                const token = localStorage.getItem("authToken")
                const response = await axios.post(
                    `https://api.rerests.com/api/v1/review/${restRoomId}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                if (response.data) {
                    Swal.fire({
                        title: ' <div class="write-review-done">  <img class= "saly-26-icon" alt="" src="./assets/img/reviewdone.png" /> <b class="thanks-for-your">Thanks for your review!</b> <div class="people-rely-on">People rely on great reviews like yours to find the best places to go.</div> <img class="./assets/img/mask-group-icon" alt="" src="./assets/img/Mask Group.png" /> <img class="d-shapewireframe-cube" alt="" src="./assets/img/3D Shape/Wireframe Cube.png" /> <img class="x-icon" alt="" src="./assets/img/X.svg" id="xIcon" /> </div > ',
                        showConfirmButton: false,
                        customClass: "reviwadd",
                        didOpen: () => {
                            document.getElementById('xIcon').addEventListener('click', () => {
                                Swal.close();
                            });
                        }

                    });
                    setReviews({
                        message: "",
                        rating: 0,
                    });
                    handleCloseModal()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Failed to submit the review. Please try again later.",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Please Sign In",
                });
            }
        } catch (error) {
            console.error("Error during Review:", error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });

    };


    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setReviews({
            message: "",
            rating: 0,
        });
        setShowModal(false)

    };
    useEffect(() => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('authToken');
        if (userId) {
            axios.get(`https://api.rerests.com/api/v1/user/${userId}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            })
                .then(response => {
                    setName(response.data.data.name);
                    if (response.data.data.image) {
                        setProfileUrl(response.data.data.image);
                    }
                })
                .catch(error => console.error("Error fetching user data", error));
        }
        const fetchReviewsRestrooms = async () => {
            try {
                const response = await axios.get(
                    `https://api.rerests.com/api/v1/review/${restRoom}`
                );

                const reviewsData = response.data.data.reviews;
                setReviewss(reviewsData);

                // Calculate average rating and star breakdown
                const totalReviews = reviewsData.length;
                setTotalReviews(totalReviews);

                if (totalReviews > 0) {
                    const totalRating = reviewsData.reduce((sum, review) => sum + review.rating, 0);
                    const averageRating = totalRating / totalReviews;
                    setAvgRating(averageRating);

                    // Calculate counts for each star rating
                    const counts = reviewsData.reduce((acc, review) => {
                        const rating = review.rating;
                        acc[rating] = (acc[rating] || 0) + 1;
                        return acc;
                    }, {});
                    setRatingCounts(counts);
                }
            } catch (error) {
                setError(error.response?.data?.message || 'An unexpected error occurred.');
            } finally {
                setLoading(false);
            }
        };

        fetchReviewsRestrooms();
    }, [restRoom]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="frame-parent13">
            <div className="divblock-parent">
                <div className="divblock">
                    <div className="row-parent">
                        {reviewss.length > 0 ?
                            <div className="row">

                                <div className="rating">
                                    {/* Star breakdown (5 to 1) */}
                                    {[5, 4, 3, 2, 1].map((star) => (
                                        <div className="row1" key={star}>
                                            <div className="mins-ago">{star}</div>
                                            <img className="row-child" alt="" src="./assets/img/star.svg" />
                                            <div className={`space${star}`} style={{
                                                width: `${(ratingCounts[star] / totalReviews) * 100 || 0
                                                    }%`,
                                                backgroundColor: '#ffc107',
                                                height: '10px',
                                            }}>
                                                {/* Show bar with width proportional to number of reviews for this star */}
                                                <div
                                                    className="filled-bar"

                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="rating1">
                                    {/* Display the average rating */}
                                    <b className="b">{avgRating.toFixed(1)}</b>
                                    <div className="row6">
                                        {/* Display stars based on average rating */}
                                        {[...Array(5)].map((_, i) => (
                                            <img
                                                key={i}
                                                className="row-child"
                                                alt=""
                                                src={
                                                    i < avgRating
                                                        ? './assets/img/star.svg'
                                                        : './assets/img/star-empty.svg'
                                                }
                                            />
                                        ))}
                                    </div>
                                    <div className="reviews2">
                                        {avgRating.toFixed(1)}  Reviews
                                    </div>
                                </div>
                            </div>
                            : null}

                        {reviewss.length > 0 ?

                            <div className="primary-buttondefault-wrapper" id="frameContainer">
                                <button onClick={handleShowModal} className="primary-buttondefault">
                                    <div className="icons">
                                    </div>
                                    <img className="location-on-icon" alt="" src="./assets/img/edit.svg" />
                                    <b className="manhattan">{showModal ? 'Close Review Form' : 'Write a Review'}</b>
                                    <div className="icons">
                                    </div>
                                </button>

                            </div>
                            : null}
                    </div>
                    {/* Display individual reviews */}
                    {reviewss.length == 0 ?
                        <>
                            <div className='reviewempty'>
                                <img src="./assets/img/pana.svg" alt="" />
                                <h3>No reviews yet</h3>
                                <p>Be the first person to add a review <br />for this place now!</p>

                            </div>
                            <div className="primary-buttondefault-wrapper mb-5 mx-auto" id="frameContainer">
                                <button onClick={handleShowModal} className="primary-buttondefault">
                                    <div className="icons">
                                    </div>
                                    <img className="location-on-icon" alt="" src="./assets/img/edit.svg" />
                                    <b className="manhattan">{showModal ? 'Close Review Form' : 'Write a Review'}</b>
                                    <div className="icons">
                                    </div>
                                </button>

                            </div>
                        </>
                        :
                        reviewss.map((review) => (
                            <div key={review.id} className="frame-parent12">
                                <div className="row7">
                                    <div className="divblock1">
                                        <div className="div14">
                                            <img
                                                className="user-image"
                                                alt={review.user.name}
                                                src={review.user.image || 'Rectangle.png'}
                                            />
                                            <div className="text">
                                                <div className="courtney-henry">{review.user.name}</div>
                                                <div className="row8">
                                                    {[...Array(review.rating)].map((_, i) => (
                                                        <img key={i} className="row-child" alt="star" src="./assets/img/star.svg" />
                                                    ))}
                                                    <div className="mins-ago">
                                                        {moment(review.createAt).fromNow()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="consequat-velit-qui">{review.comment}</div>
                                </div>
                            </div>
                        ))}
                    {showModal && selectedRestroom && (
                        <Modal show={showModal} onHide={handleCloseModal}>

                            <Modal.Body className="popReview">
                                <>

                                    <div id="writeReviewWindow" className="popup-overlay" >
                                        <form className="write-review-window" onSubmit={(e) => submit(e, selectedRestroom.id)}>
                                            <div className="stepney-city-farm2" >{selectedRestroom.name}</div>
                                            <div className="d-flex align-items-center">
                                                <img src={profileUrl ? profileUrl : "./assets/img/profile.png"} alt="" className="asse" />
                                                <div className="abdullrahman-alaraby">{name}</div>
                                            </div>
                                            <div className="component-14-group ">
                                                <ReactStars
                                                    count={5}
                                                    onChange={onRatingChange}
                                                    size={50}
                                                    emptyIcon={<CiStar />
                                                    }
                                                    filledIcon={<FaStar />}
                                                    activeColor="#ffd700"
                                                    color="#ffd700"
                                                    value={rating}
                                                />
                                            </div>
                                            <textarea
                                                value={message}
                                                name="message"
                                                onChange={(e) => onInputChange(e)}

                                                className="write-review-window-child" placeholder="Share details of your own experience at this place">
                                            </textarea>
                                            <div className="frame-parent">
                                                <button type="buuton" className="cancel-wrapper" id="frameContainer" onClick={handleCloseModal}>
                                                    <div className="add-photos">Cancel</div>
                                                </button>
                                                <button type="submit" className="post-wrapper" id="frameContainer1">
                                                    <b className="post">Post</b>
                                                </button>
                                            </div>

                                            <img className="x-icon" alt="" src="./assets/img/X.svg" id="popupxIcon" onClick={handleCloseModal} />
                                        </form>
                                    </div>

                                </>
                            </Modal.Body>

                        </Modal>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Reviews;
