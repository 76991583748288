import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import { Col, Container, Row } from 'react-bootstrap';
import { SwiperSlide, Swiper } from 'swiper/react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';

function Favorite() {
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"
        }
    })
    const [favorites, setFavorites] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await fetch('https://api.rerests.com/api/v1/user/favorites', {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const data = await response.json();

                setFavorites(data.data);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            }
        };

        fetchFavorites();
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/SignIn");
        }
    }, [navigate]);

    const removeFavorite = async (restRoomId) => {
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://api.rerests.com/api/v1/user/${restRoomId}/favorite`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.ok) {
                setFavorites(favorites.filter(favorite => favorite.restRoomId !== restRoomId));
            } else {
                console.error('Failed to remove favorite');
            }
        } catch (error) {
            console.error('Error removing favorite:', error);
        }
    };
    const handleMoreDetailsClick = (restRoom) => {
        if (window.innerWidth <= 1024) {

               document.body.style.height = "100vh"
            document.body.style.overflowY = "hidden"
        }
        navigate("/", { state: { selectedRoom: restRoom } });
    };
    return (
        <div className='favoritePage'>
            <Container>
                <div className="my-favorites-list mt-4 mb-3 px-3">
                    <img src="./assets/img/favoritenav.svg" alt="" />My Favorites list
                </div>
                <Row>
                    {favorites?.map((favorite) => (
                        <Col lg="3" md="6" sm="12" key={favorite.restRoomId} className="cardrestroom px-3">
                            <div className="rectangle-parent" style={{ minHeight: "380px" }}>
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    autoplay={{ delay: 3000 }}
                                    className="photo-swiper"
                                    navigation={true}
                                    modules={[FreeMode, Navigation, Pagination]}
                                >
                                    {favorite.restRoom.photos.map(photo => (
                                        <SwiperSlide key={photo.id}>
                                            <img src={photo.url} alt="Restroom" className="restroom-photo" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="frame-parent">
                                    <div className="vector-parent">
                                        <div className="miles-from-your">{favorite.restRoom.name}</div>
                                    </div>
                                    <div className="frame-group">
                                        <div className="manx-museum-wrapper">
                                            <div className="manx-museum">{favorite.restRoom.name}</div>
                                        </div>
                                        <div className="frame-wrapper">
                                            <div className="parent">
                                                <div className="div">{favorite.restRoom.avgRating}</div>
                                                <div className="star-wrapper">
                                                    <div className="star">
                                                        {Array.from({ length: Math.max(0, Math.floor(favorite.restRoom.avgRating)) }).map((_, i) => (
                                                            <img key={i} className="vector-icon1" alt="" src="./assets/img/search/star.svg" />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="ratings" id="ratingsText">
                                                    <span className="ratings-txt-container">
                                                        <span>( </span>
                                                        <span className="ratings1">{favorite.totalReviews} ratings</span>
                                                        <span> )</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-container">
                                        <div className="manhattan-wrapper">
                                            <div className="miles-from-your">{favorite.restRoom.address}</div>
                                        </div>
                                    </div>
                                    <div className="frame-div d-flex justify-content-between">
                                        <div className="closed-parent">
                                            <div className="div">{favorite.restRoom.isVerified ? "Verified" : "Not Verified"}</div>
                                        </div>
                                        <div onClick={() => handleMoreDetailsClick(favorite.restRoom)}  className="more-details-wrapper" id="frameContainer1" >
                                            <div className="more-details" >More details</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rectangle-group">
                                    <div className="group-child"></div>
                                    <div className="paid">Paid</div>
                                    <div className="vector-wrapper">
                                        <img className="vector-icon7" alt="" src="./assets/img/dollar.svg" />
                                    </div>
                                </div>
                                <button className="remove-parent" onClick={() => removeFavorite(favorite.restRoomId)}>
                                    Remove
                                    <img className="vector-icon8" alt="" src="./assets/img/remove.svg" />
                                </button>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default Favorite;
