import React from "react";
import { Link, useLocation } from "react-router-dom";


function Footer() {
    const location = useLocation(); 
    const imagePath = location.pathname.includes("/edit-room/") ? "../assets" : "./assets/";
  
  return (
    <footer>
 
    <div className="divFooter">
        <div className="footer">
            <div><img src={`${imagePath}/img/logoFooter.svg`} alt="" className="footerImg"/></div>
            <div className="logofooter">ReRest</div>
        </div>
        <div className="typegraphy">
            <div>Your Guide to Clean, Comfortable, and Convenient <br /> Restrooms Everywhere!</div>
        </div>
        <div className="link-page">
            <ul className="j">
                <li className="li9"> <Link to="/Contact">Contact </Link></li>
                <li className="li9"> <Link to="/About">About us</Link></li>
            </ul>
        </div>
        <div className="gapp"></div>
        <div className="logo-footer">
            <img src={`${imagePath}/Group (8).svg`}alt="" />
            <img src={`${imagePath}/Group (9).svg`}alt="" />
            <img src={`${imagePath}/Group (10).svg`} alt="" />
            <img src={`${imagePath}/Group (11).svg`} alt="" />

        </div>
        <div className="gapp"></div>
        <div className="read first">Copyright 2024</div>
        <div className="read">ReRest</div>
    </div>
</footer>
  );
}

export default Footer;
