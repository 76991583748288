import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Footer from './Footer';

function ResetPass() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [user, setUser] = useState({
    password: "",
    confirm_password: ""
  });
  const navigate = useNavigate();
  const location = useLocation();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const togglePasswordVisibility2 = () => {
    setIsPasswordVisible2(!isPasswordVisible2);
  };

  // Extract token from URL
  const getTokenFromURL = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  };

  const token = getTokenFromURL();

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, [navigate]);

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirm_password) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please check and try again.'
      });
      return;
    }

    try {
      const payload = {
        password: user.password,
        token: token, 
      };


      const response = await axios.post("https://api.rerests.com/api/v1/auth/reset-password", payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {

        navigate('/reset-pass-success'); // Redirect after successful reset
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Reset Failed',
          text: 'Please try again.'
        });
      }
    } catch (error) {
      if (error.response) {
        console.error("Response data:", error.response.data);
        Swal.fire({
          icon: 'error',
          title: 'Reset Failed',
          text: error.response.data.message || 'Please check your input and try again.'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Reset Failed',
          text: error.message
        });
      }
    }
  };

  return (
    <>
      <div className='signup-signin pass'>
        <div className="login-sign-in">
          <div className="login-sign-in-inner">
            <div className="frame-parent">
            <div className="frame-group">
               
                <div className="sign-in-wrapper">
                  <div className="sign-in">Change Password</div>
                </div>
              </div>
              <form onSubmit={onSubmit} className="frame-wrapper">
                <div className="frame-container">
                  <div className="frame-div">
                    <div className="frame-parent1">
                      <div className="frame-wrapper1">
                        <div className="frame-parent2">
                          <div className="frame-wrapper2">
                            <div className="email-wrapper">
                              <div className="sign-up">Password</div>
                            </div>
                          </div>
                          <div className="enter-your-password-here-parent">
                            <div className="enter-your-password">
                              <input
                                name="password"
                                value={user.password}
                                onChange={onInputChange}
                                className="password-password"
                                placeholder="Enter your new password here"
                                type={isPasswordVisible ? "text" : "password"}
                                id="password0"
                              />
                            </div>
                            <img
                              className="EyeSlash"
                              alt=""
                              onClick={togglePasswordVisibility}
                              src={`./assets/img/${isPasswordVisible ? 'Eye.svg' : 'EyeSlash.svg'}`}
                              id="eyeicon"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="frame-wrapper3 mt-4">
                        <div className="frame-parent2">
                          <div className="frame-wrapper2">
                            <div className="email-wrapper">
                              <div className="sign-up">Confirm Password</div>
                            </div>
                          </div>
                          <div className="enter-your-password-here-parent">
                            <div className="enter-your-password">
                              <input
                                name="confirm_password"
                                value={user.confirm_password}
                                onChange={onInputChange}
                                className="password-password"
                                placeholder="Confirm your new password"
                                type={isPasswordVisible2 ? "text" : "password"}
                                id="password1"
                              />
                            </div>
                            <img
                              className="EyeSlash"
                              alt=""
                              onClick={togglePasswordVisibility2}
                              src={`./assets/img/${isPasswordVisible2 ? 'Eye.svg' : 'EyeSlash.svg'}`}
                              id="eyeicon"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="frame-wrapper6 mt-4">
                        <button type='submit' className="sign-in-container" id="frameContainer">
                          <div className="sign-up">Reset Password</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResetPass;
