import React, { useEffect } from 'react'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';

function NotFound() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/")
  }, [])
  return (
    <>
      <div className='error py-3 my-3'>
        <img style={{width:"100%" , objectFit:"contain"}} className="rectangle-icon" alt="" src="./assets/img/Frame 221.svg" />

      </div>
      <Footer />
    </>
  )
}

export default NotFound
