import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios'; // Make sure you have axios installed
import Swal from 'sweetalert2';

function EditProfile() {
    useEffect(() => {
        if (window.innerWidth <= 1024) {
            document.body.style.height = "unset"
            document.body.style.overflowY = "unset"



        }
    })
    const [profileUrl, setProfileUrl] = useState("./assets/img/profile.png");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [editing, setEditing] = useState(false);
    const [newName, setNewName] = useState(name);
    const [newProfileUrl, setNewProfileUrl] = useState(profileUrl);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('authToken'); // Retrieve the token if needed
        if (userId) {
            axios.get(`https://api.rerests.com/api/v1/user/${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    setName(response.data.data.name);
                    if (response.data.data.image) {
                        setProfileUrl(response.data.data.image);
                    }

                    setNewName(response.data.data.name);
                    setEmail(response.data.data.email);

                    setNewProfileUrl(response.data.data.image);

                })
                .catch(error => {
                    console.error("Error fetching user data", error);
                });
        }
    }, []);

    const handleEditClick = () => {
        setEditing(true);
    };


    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newPhotosPromises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    // Extract Base64 part from the full Data URL
                    const base64Data = base64String.split(',')[1];
                    resolve(base64Data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newPhotosPromises).then((newPhotos) => {
            setNewProfileUrl(`${newPhotos[0]}`); // Assuming you only handle one profile image
            setSelectedFile(files[0]); // Handle the selected file if needed
        }).catch(error => {
            console.error('Error reading files:', error);
        });
    };

    const handleSaveClick = (e) => {
        e.preventDefault()
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('authToken');

        if (userId) {
            // const formData = new FormData();
            // formData.append('name', newName);
            const payload = {
                name: newName,
                image: newProfileUrl,
            }

            axios.patch(`https://api.rerests.com/api/v1/user/${userId}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                    setName(newName);

                    // If a new file was uploaded, update the profileUrl with the new image's URL (you may need to get this from the response)
                    if (response.data.data.profileImage) {
                        setProfileUrl(response.data.data.profileImage);
                    }
                    Swal.fire({
                        title: "Success",
                        text: "Changes saved successfully",
                        customClass: "styleTitle",
                        position: 'top-end', // Top-right corner
                        toast: true, // Make it a toast
                        showConfirmButton: false,
                        timer: 3000, // Auto-dismiss after 3 seconds
                        timerProgressBar: true,
                    });
                    setEditing(false);
                })
                .catch(error => {
                    Swal.fire({
                        title: "Error",
                        text: "Something went wrong",
                        customClass: "styleTitle2",
                        position: 'top-end', // Top-right corner
                        toast: true, // Make it a toast
                        showConfirmButton: false,
                        timer: 3000, // Auto-dismiss after 3 seconds
                        timerProgressBar: true,
                    });
                });
        }
    };

    return (
        <>
            <form onSubmit={handleSaveClick} className='edit-profilee'>
                <Container>
                    <Row className='my-5 py-4 justify-content-center'>
                        <Col lg={3} md={12}>
                            <div className="component-15-parent">
                                <div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                    <label htmlFor="fileInput">
                                        {selectedFile ? (
                                            <div className="imageFile upload">
                                                <img className="component-15-icon" src={URL.createObjectURL(selectedFile)} alt="" />
                                            </div>
                                        ) : (
                                            <div className="imageFile imggg">
                                                <img className="component-15-icon" src={profileUrl ? profileUrl : "./assets/img/profile.png"} alt="" />
                                            </div>
                                        )}
                                    </label>
                                </div>
                                <div className="ahmad-ali-wrapper">
                                    <div className="ahmad-ali">
                                        {editing ? (
                                            <input
                                                type="text"
                                                value={newName}
                                                onChange={(e) => setNewName(e.target.value)}
                                                className="name-input"
                                            />
                                        ) : (
                                            <span>{name}</span>
                                        )}
                                     
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="edit-profile">
                            {localStorage.getItem("googlesigned")?
                                <div class="connect-parent">
                                    <div class="connect">Connect</div>
                                    <div class="google-parent">
                                        <img class="google-icon" alt="" src="assets/img/Google (1).svg" />
                                        <div class="registered-with-google">REGISTERED WITH GOOGLE</div>
                                    </div>
                                </div>
                            
                            :null}
                           
                                <div className="account-data-parent">
                                    <div className="account-data">Account data</div>
                                    <div className="frame-parent">
                                        <div className="frame-parent">
                                            <div className="name-wrapper">
                                                <div className="name">Name</div>
                                            </div>
                                        </div>
                                        <input
                                            className="ahmad-ali-parent"
                                            value={newName}
                                            onChange={(e) => setNewName(e.target.value)}
                                        // disabled={!editing}
                                        />
                                        <img className="group-icon" alt="" src="./assets/img/Group (5).svg" />
                                    </div>
                                    <div className="frame-parent">
                                        <div className="frame-parent">
                                            <div className="name-wrapper">
                                                <div className="name">Email</div>
                                            </div>
                                        </div>
                                        <input
                                            className="ahmadali123gmailcom-wrapper"
                                            value={email}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="frame-div">
                                    <button type='reset' className="cancel-wrapper">
                                        <div
                                            className="cancel"
                                        >
                                            Cancel
                                        </div>
                                    </button>
                                    <button className="save-wrapper" id="frameContainer1" type='submit' >
                                        <div
                                            className="save"

                                        >
                                            Save
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
            <Footer />
        </>
    );
}

export default EditProfile;
